define("ember-bootstrap/components/base/bs-form/element/layout", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  /**
  
   @class FormElementLayout
   @namespace Components
   @extends Ember.Component
   @private
   */
  let FormElementLayout = (_dec = (0, _component.tagName)(''), _dec(_class = (_class2 = class FormElementLayout extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * @property formElementId
       * @type {String}
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "formElementId", _descriptor, this);
      /**
       * @property hasLabel
       * @type boolean
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "hasLabel", _descriptor2, this);
      /**
       * @property errorsComponent
       * @type {Ember.Component}
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "errorsComponent", _descriptor3, this);
      /**
       * @property feedbackIconComponent
       * @type {Ember.Component}
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "feedbackIconComponent", _descriptor4, this);
      /**
       * @property labelComponent
       * @type {Ember.Component}
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "labelComponent", _descriptor5, this);
      /**
       * @property helpTextComponent
       * @type {Ember.Component}
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "helpTextComponent", _descriptor6, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "formElementId", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "errorsComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "feedbackIconComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "labelComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "helpTextComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class2)) || _class);
  _exports.default = FormElementLayout;
});