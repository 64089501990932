define("ember-bootstrap/components/bs-popover/element", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-bootstrap/components/base/bs-popover/element"], function (_exports, _applyDecoratedDescriptor2, _element) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let PopoverElement = (_dec = Ember.computed('fade', 'actualPlacement', 'showHelp'), (_class = class PopoverElement extends _element.default {
    constructor() {
      super(...arguments);
      /**
       * @property titleClass
       * @private
       */
      this.titleClass = 'popover-header';
      /**
       * @property contentClass
       * @private
       */
      this.contentClass = 'popover-body';
    }
    get popperClassNames() {
      let classes = ['popover', `bs-popover-${this.get('actualPlacement')}`];
      if (this.get('fade')) {
        classes.push('fade');
      }
      if (this.get('showHelp')) {
        classes.push('show');
      }
      return classes;
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "popperClassNames", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "popperClassNames"), _class.prototype)), _class));
  _exports.default = PopoverElement;
});