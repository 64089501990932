define("ember-bootstrap/components/base/bs-accordion/item", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-accordion/item", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _item, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  /**
   A collapsible/expandable item within an accordion
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionItem
   @namespace Components
   @extends Ember.Component
   @public
   */
  let AccordionItem = (_dec = (0, _component.layout)(_item.default), _dec2 = (0, _component.classNameBindings)('disabled', 'typeClass'), _dec3 = Ember.computed.oneWay('elementId'), _dec4 = Ember.computed('value', 'selected').readOnly(), _dec5 = Ember.computed.not('collapsed'), _dec(_class = _dec2(_class = (_class2 = class AccordionItem extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * The title of the accordion item, displayed as a .panel-title element
       *
       * @property title
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "title", _descriptor, this);
      /**
       * The value of the accordion item, which is used as the value of the `selected` property of the parent [Components.Accordion](Components.Accordion.html) component
       *
       * @property value
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor2, this);
      /**
       * @property selected
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "selected", _descriptor3, this);
      /**
       * @property titleComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "titleComponent", _descriptor4, this);
      /**
       * @property bodyComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "bodyComponent", _descriptor5, this);
      /**
       * @property active
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "active", _descriptor6, this);
      /**
       * @property disabled
       * @type boolean
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "disabled", _descriptor7, this);
      /**
       * Property for type styling
       *
       * For the available types see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/navbar/#color-schemes)
       *
       * @property type
       * @type String
       * @default 'default'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor8, this);
    }
    /**
     * @property collapsed
     * @type boolean
     * @readonly
     * @private
     */
    get collapsed() {
      return this.get('value') !== this.get('selected');
    }
    /**
     * Reference to the parent `Components.Accordion` class.
     *
     * @property accordion
     * @private
     */
    /**
     * @event onClick
     * @public
     */
    onClick() {}
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "title", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "value", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selected", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "titleComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-accordion/item/title';
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "bodyComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-accordion/item/body';
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "collapsed", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "collapsed"), _class2.prototype), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "active", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "disabled", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  })), _class2)) || _class) || _class);
  _exports.default = AccordionItem;
});