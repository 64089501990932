define("ember-bootstrap/components/base/bs-dropdown/button", ["exports", "@ember-decorators/component", "ember-bootstrap/components/bs-button"], function (_exports, _component, _bsButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  /**
   Button component with that can act as a dropdown toggler.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownButton
   @namespace Components
   @extends Components.Button
   @public
   */
  let DropdownButton = (_dec = (0, _component.classNames)('dropdown-toggle'), _dec(_class = class DropdownButton extends _bsButton.default {
    didReceiveAttrs() {
      // super.didReceiveAttrs(...arguments);
      let dropdown = this.get('dropdown');
      if (dropdown) {
        Ember.run.schedule('actions', this, function () {
          if (!this.get('isDestroyed')) {
            dropdown.set('toggle', this);
          }
        });
      }
    }
  }) || _class);
  _exports.default = DropdownButton;
});