define("ember-bootstrap/components/base/bs-accordion", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-accordion", "ember-bootstrap/utils/cp/listen-to", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _bsAccordion, _listenTo, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  /**
    Bootstrap-style [accordion group](http://getbootstrap.com/javascript/#collapse-example-accordion),
    with collapsible/expandable items.
  
    ### Usage
  
    Use as a block level component with any number of yielded [Components.AccordionItem](Components.AccordionItem.html)
    components as children:
  
    ```handlebars
    <BsAccordion as |Acc|>
      <Acc.item @value={{1}} @title="First item">
        <p>Lorem ipsum...</p>
        <button onclick={{action acc.change 2}}>Next</button>
      </Acc.item>
      <Acc.item @value={{2}} @title="Second item">
        <p>Lorem ipsum...</p>
      </Acc.item>
      <Acc.item @value={{3}} @title="Third item">
        <p>Lorem ipsum...</p>
      </Acc.item>
    </BsAccordion>
    ```
  
    In the example above the first accordion item utilizes the yielded `change` action to add some custom behaviour.
  
    @class Accordion
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Accordion = (_dec = (0, _component.layout)(_bsAccordion.default), _dec2 = (0, _listenTo.default)('selected'), _dec(_class = (_class2 = class Accordion extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ariaRole", _descriptor, this);
      /**
       * The value of the currently selected accordion item. Set this to change selection programmatically.
       *
       * When the selection is changed by user interaction this property will not update by using two-way bindings in order
       * to follow DDAU best practices. If you want to react to such changes, subscribe to the `onChange` action
       *
       * @property selected
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "selected", _descriptor2, this);
      /**
       * @property itemComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "itemComponent", _descriptor3, this);
      /**
       * The value of the currently selected accordion item
       *
       * @property isSelected
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "isSelected", _descriptor4, this);
    }
    /**
     * Action when the selected accordion item is about to be changed.
     *
     * You can return false to prevent changing the active item, and do that in your action by
     * setting the `selected` accordingly.
     *
     * @event onChange
     * @param newValue
     * @param oldValue
     * @public
     */
    onChange(newValue, oldValue) {} // eslint-disable-line no-unused-vars

    doChange(newValue) {
      let oldValue = this.get('isSelected');
      if (oldValue === newValue) {
        newValue = null;
      }
      if (this.get('onChange')(newValue, oldValue) !== false) {
        this.set('isSelected', newValue);
      }
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "ariaRole", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'tablist';
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selected", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "itemComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-accordion/item';
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isSelected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doChange", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "doChange"), _class2.prototype)), _class2)) || _class);
  _exports.default = Accordion;
});