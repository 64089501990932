define("ember-google-maps/templates/components/-private-api/addon-factory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dz0zk06h",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/-private-api/addon-factory.hbs"
    }
  });
  _exports.default = _default;
});