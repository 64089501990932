define("ember-bootstrap/components/base/bs-button", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/templates/components/bs-button", "ember-bootstrap/utils/cp/size-class", "ember-bootstrap/utils/cp/type-class", "ember-bootstrap/utils/cp/overrideable", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _object, _bsButton, _sizeClass, _typeClass, _overrideable, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25;
  /**
    Implements a HTML button element, with support for all [Bootstrap button CSS styles](http://getbootstrap.com/css/#buttons)
    as well as advanced functionality such as button states.
  
    ### Basic Usage
  
    ```hbs
    <BsButton @type="primary" @icon="glyphicon glyphicon-download">
      Downloads
    </BsButton>
    ```
  
    ### Actions
  
    Use the `onClick` property of the component to send an action to your controller. It will receive the button's value
    (see the `value` property) as an argument.
  
    ```hbs
    <BsButton @type="primary" @icon="glyphicon glyphicon-download" @onClick=(action "download")>
      Downloads
    </BsButton>
    ```
  
    ### Promise support for automatic state change
  
    When returning a Promise for any asynchronous operation from the `onClick` closure action the button will
    manage an internal state ("default" > "pending" > "fulfilled"/"rejected") automatically, changing its label
    according to the state of the promise:
  
    ```hbs
    <BsButton
      (at)type="primary"
      (at)icon="glyphicon glyphicon-download"
      (at)defaultText="Download"
      (at)pendingText="Loading..."
      (at)fulfilledText="Completed!"
      (at)rejectedText="Oups!?"
      (at)onClick={{action "download"}}
    />
    ```
  
    ```js
    // controller.js
    export default Ember.Controller.extend({
      actions: {
        download(value) {
          return new Ember.RSVP.Promise(...);
        }
      }
    });
    ```
  
    For further customization `isPending`, `isFulfilled`, `isRejected` and `isSettled` properties are yielded:
  
    ```hbs
    <BsButton @onClick=(action "download") as |button|>
      Download
      {{#if button.isPending}}
        <span class="loading-spinner"></span>
      {{/if}}
    </BsButton>
    ```
  
    You can `reset` the state represented by these properties and used for button's text by setting `reset` property to
    `true`.
  
    @class Button
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Button = (_dec = (0, _component.layout)(_bsButton.default), _dec2 = (0, _component.tagName)('button'), _dec3 = (0, _component.classNames)('btn'), _dec4 = (0, _component.classNameBindings)('active', 'block:btn-block', 'sizeClass', 'typeClass'), _dec5 = (0, _component.attributeBindings)('_disabled:disabled', 'buttonType:type', 'title'), _dec6 = Ember.computed('disabled', 'isPending', 'preventConcurrency'), _dec7 = (0, _overrideable.default)('active', function () {
    if (this.get('active')) {
      return this.get('iconActive');
    } else {
      return this.get('iconInactive');
    }
  }), _dec8 = Ember.computed.equal('state', 'pending'), _dec9 = Ember.computed.equal('state', 'fulfilled'), _dec10 = Ember.computed.equal('state', 'rejected'), _dec11 = Ember.computed.or('isFulfilled', 'isRejected'), _dec12 = (0, _sizeClass.default)('btn', 'size'), _dec13 = (0, _typeClass.default)('btn', 'type'), _dec14 = (0, _object.observes)('reset'), _dec15 = Ember.computed('state', 'defaultText', 'pendingText', 'fulfilledText', 'rejectedText'), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = _dec5(_class = (_class2 = class Button extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * Default label of the button. Not need if used as a block component
       *
       * @property defaultText
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "defaultText", _descriptor, this);
      /**
       * Label of the button used if `onClick` event has returned a Promise which is pending.
       * Not considered if used as a block component.
       *
       * @property pendingText
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "pendingText", _descriptor2, this);
      /**
       * Label of the button used if `onClick` event has returned a Promise which succeeded.
       * Not considered if used as a block component.
       *
       * @property fulfilledText
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "fulfilledText", _descriptor3, this);
      /**
       * Label of the button used if `onClick` event has returned a Promise which failed.
       * Not considered if used as a block component.
       *
       * @property rejectedText
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "rejectedText", _descriptor4, this);
      /**
       * Property to disable the button
       *
       * The button is disabled by default if `preventConcurrency` is `true` and
       * a Promise returned by `onClick` action is pending. You may explicitly
       * enable / disable the button by setting `disabled` to `true` or `false`.
       * Setting it to `null` restores the default behavior.
       *
       * @property disabled
       * @type ?boolean
       * @default null
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "disabled", _descriptor5, this);
      /**
       * Set the type of the button, either 'button' or 'submit'
       *
       * @property buttonType
       * @type String
       * @default 'button'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "buttonType", _descriptor6, this);
      /**
       * Set the 'active' class to apply active/pressed CSS styling
       *
       * @property active
       * @type boolean
       * @default false
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "active", _descriptor7, this);
      /**
       * Property for block level buttons
       *
       * See the [Bootstrap docs](http://getbootstrap.com/css/#buttons-sizes)
       * @property block
       * @type boolean
       * @default false
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "block", _descriptor8, this);
      /**
       * A click event on a button will not bubble up the DOM tree if it has an `onClick` action handler. Set to true to
       * enable the event to bubble
       *
       * @property bubble
       * @type boolean
       * @default false
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "bubble", _descriptor9, this);
      /**
       * If button is active and this is set, the icon property will match this property
       *
       * @property iconActive
       * @type String
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "iconActive", _descriptor10, this);
      /**
       * If button is inactive and this is set, the icon property will match this property
       *
       * @property iconInactive
       * @type String
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "iconInactive", _descriptor11, this);
      /**
       * Class(es) (e.g. glyphicons or font awesome) to use as a button icon
       * This will render a <i class="{{icon}}"></i> element in front of the button's label
       *
       * @property icon
       * @type String
       * @readonly
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "icon", _descriptor12, this);
      /**
       * Supply a value that will be associated with this button. This will be send
       * as a parameter of the default action triggered when clicking the button
       *
       * @property value
       * @type any
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor13, this);
      /**
       * Controls if `onClick` action is fired concurrently. If `true` clicking button multiple times will not trigger
       * `onClick` action if a Promise returned by previous click is not settled yet.
       *
       * This does not affect event bubbling.
       *
       * @property preventConcurrency
       * @type Boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "preventConcurrency", _descriptor14, this);
      /**
       * State of the button. The button's label (if not used as a block component) will be set to the
       * `<state>Text` property.
       * This property will automatically be set when using a click action that supplies the callback with a promise.
       * Possible values are: "default" > "pending" > "fulfilled" / "rejected".
       * It could be reseted by `reset` property.
       *
       * @property state
       * @type String
       * @default 'default'
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "state", _descriptor15, this);
      /**
       * Promise returned by `onClick` event is pending.
       *
       * @property isPending
       * @type Boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "isPending", _descriptor16, this);
      /**
       * Promise returned by `onClick` event has been succeeded.
       *
       * @property isFulfilled
       * @type Boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "isFulfilled", _descriptor17, this);
      /**
       * Promise returned by `onClick` event has been rejected.
       *
       * @property isRejected
       * @type Boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "isRejected", _descriptor18, this);
      /**
       * Promise returned by `onClick` event has been succeeded or rejected.
       *
       * @property isFulfilled
       * @type Boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "isSettled", _descriptor19, this);
      /**
       * Set this to `true` to reset the `state`. A typical use case is to bind this attribute with ember-data isDirty flag.
       *
       * The old value is not taken into consideration. Setting a `true` value to `true` again will also reset `state`.
       * In that case it's even to notify the observer system that the property has changed by calling
       * [`notifyPropertyChange()`](https://www.emberjs.com/api/ember/3.2/classes/EmberObject/methods/notifyPropertyChange?anchor=notifyPropertyChange).
       *
       * @property reset
       * @type boolean
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "reset", _descriptor20, this);
      /**
       * Property for size styling, set to 'lg', 'sm' or 'xs'
       *
       * Also see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/buttons/#sizes)
       *
       * @property size
       * @type String
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "size", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "sizeClass", _descriptor22, this);
      /**
       * Property for type styling
       *
       * For the available types see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/buttons/)
       *
       * @property type
       * @type String
       * @default 'default'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "typeClass", _descriptor23, this);
      /**
       * The HTML title attribute
       *
       * @property title
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "title", _descriptor24, this);
      /**
       * When clicking the button this action is called with the value of the button (that is the value of the "value" property).
       *
       * Return a promise object, and the buttons state will automatically set to "pending", "resolved" and/or "rejected".
       * This could be used to automatically set the button's text depending on promise state (`defaultText`, `pendingText`,
       * `fulfilledText`, `rejectedText`) and for futher customization using the yielded `isPending`, `isFulfilled`,
       * `isRejected` properties.
       *
       * The click event will not bubble up, unless you set `bubble` to true.
       *
       * @event onClick
       * @param {*} value
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "onClick", _descriptor25, this);
    }
    get _disabled() {
      if (this.get('disabled') !== null) {
        return this.get('disabled');
      }
      return this.get('isPending') && this.get('preventConcurrency');
    }
    /**
     * This will reset the state property to 'default', and with that the button's label to defaultText
     *
     * @method resetState
     * @private
     */
    resetState() {
      this.set('state', 'default');
    }
    resetObserver() {
      if (this.get('reset')) {
        Ember.run.scheduleOnce('actions', this, 'resetState');
      }
    }
    get text() {
      return this.getWithDefault(`${this.get('state')}Text`, this.get('defaultText'));
    }

    /**
     * @method click
     * @private
     */
    click() {
      let action = this.get('onClick');
      let preventConcurrency = this.get('preventConcurrency');
      if (action === null || action === undefined) {
        return;
      }
      if (!preventConcurrency || !this.get('isPending')) {
        let promise = action(this.get('value'));
        if (promise && typeof promise.then === 'function' && !this.get('isDestroyed')) {
          this.set('state', 'pending');
          promise.then(() => {
            if (!this.get('isDestroyed')) {
              this.set('state', 'fulfilled');
            }
          }, () => {
            if (!this.get('isDestroyed')) {
              this.set('state', 'rejected');
            }
          });
        }
      }
      return this.get('bubble');
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "defaultText", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "pendingText", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fulfilledText", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "rejectedText", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "disabled", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "_disabled", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "_disabled"), _class2.prototype), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "buttonType", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button';
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "active", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "block", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "bubble", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "iconActive", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "iconInactive", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "icon", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "value", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "preventConcurrency", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "state", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isPending", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isFulfilled", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isRejected", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isSettled", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "reset", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sizeClass", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "typeClass", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "title", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onClick", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "resetObserver", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "resetObserver"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "text", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "text"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class) || _class);
  _exports.default = Button;
});