define("ember-bootstrap/components/bs-accordion/item", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/components/base/bs-accordion/item", "ember-bootstrap/utils/cp/type-class"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _item, _typeClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  let AccordionItem = (_dec = (0, _component.classNames)('card'), _dec2 = (0, _typeClass.default)('bg', 'type'), _dec(_class = (_class2 = class AccordionItem extends _item.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "typeClass", _descriptor, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "typeClass", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = AccordionItem;
});