define("ember-bootstrap/components/base/bs-navbar/toggle", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-navbar/toggle", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _toggle, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor;
  /**
   * Component to implement the responsive menu toggle behavior in a [Components.Navbar](Components.Navbar.html)
   * component. Have a look there for examples.
   *
   * ### Bootstrap 3/4 Notes
   *
   * The inline version of the component uses the triple `icon-bar` styling for Bootstrap 3 and the
   * `navbar-toggler-icon` styling for Bootstrap 4.
   *
   * @class NavbarToggle
   * @namespace Components
   * @extends Ember.Component
   * @public
   */
  let NavbarToggle = (_dec = (0, _component.layout)(_toggle.default), _dec2 = (0, _component.tagName)('button'), _dec3 = (0, _component.classNameBindings)('collapsed'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class NavbarToggle extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "collapsed", _descriptor, this);
    }
    /**
     * @event onClick
     * @public
     */
    onClick() {}
    click() {
      this.onClick();
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "collapsed", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  })), _class2)) || _class) || _class) || _class);
  _exports.default = NavbarToggle;
});