define("ember-leaflet/helpers/point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.point = _exports.default = void 0;
  const isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  const point = isFastBoot ? function () {} : function point(params) {
    return L.point(...params);
  };
  _exports.point = point;
  var _default = Ember.Helper.helper(point);
  _exports.default = _default;
});