define("ember-bootstrap/components/base/bs-carousel", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/components/bs-carousel/slide", "ember-bootstrap/mixins/component-parent", "ember-bootstrap/templates/components/bs-carousel", "ember-concurrency", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _object, _slide, _componentParent, _bsCarousel, _emberConcurrency, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26;
  /**
    Ember implementation of Bootstrap's Carousel. Supports all original features but API is partially different:
  
    | Description | Original | Component |
    | ------ | ------ | ------ |
    | Autoplays after first user event or on page load. | ride='carousel'\|false | autoPlay=false\|true |
    | Disable automatic cycle. | interval=false | interval=0 |
    | If first slide should follow last slide on "previous" event, the opposite will also be true for "next" event. | wrap=false\|true | wrap=false\|true |
    | Jumps into specific slide index | data-slide-to=n | index=n |
    | Keyboard events. | keyboard=false\|true | keyboard=false\|true |
    | Left-to-right or right-to-left sliding. | N/A |  ltr=false\|true |
    | Pause current cycle on mouse enter. | pause='hover'\|null | pauseOnMouseEnter=false\|true |
    | Show or hide controls  | Tag manipulation. | showControls=false\|true |
    | Show or hide indicators  | Tag manipulation. | showIndicators=false\|true |
    | Waiting time of slides in a automatic cycle. | interval=n | interval=n |
  
    Default settings are the same as the original so you don't have to worry about changing parameters.
  
    ```hbs
    <BsCarousel as |car|>
      <car.slide>
        <img alt="First slide" src="slide1.jpg">
      </car.slide>
      <car.slide>
        <img alt="Second slide" src="slide2.jpg">
      </car.slide>
      <car.slide>
        <img alt="Third slide" src="slide3.jpg">
      </car.slide>
    </BsCarousel>
    ```
  
    To better understand the whole documentation, you should be aware of the following operations:
  
    | Operation | Description |
    | ------ | ------ |
    | Transition | Swaps two slides. |
    | Interval | Waiting time after a transition. |
    | Presentation | Represents a single transition, or a single interval, or the union of both. |
    | Cycle | Presents all slides until it reaches first or last slide. |
    | Wrap | wrap slides, cycles without stopping at first or last slide. |
    ```
  
    @class Carousel
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Carousel = (_dec = (0, _component.attributeBindings)('tabindex'), _dec2 = (0, _component.classNames)('carousel', 'slide'), _dec3 = (0, _component.layout)(_bsCarousel.default), _dec4 = Ember.computed('wrap', 'currentIndex'), _dec5 = Ember.computed('childSlides.length', 'wrap', 'currentIndex'), _dec6 = Ember.computed.filter('children', function (view) {
    return view instanceof _slide.default;
  }).readOnly(), _dec7 = (0, _object.observes)('childSlides.[]', 'autoPlay'), _dec8 = Ember.computed('childSlides', 'currentIndex').readOnly(), _dec9 = Ember.computed('childSlides', 'followingIndex').readOnly(), _dec10 = Ember.computed.gt('interval', 0).readOnly(), _dec11 = (0, _object.observes)('index'), _dec12 = Ember.computed('childSlides.length'), _dec13 = Ember.computed.lte('childSlides.length', 1), _dec14 = Ember.computed.readOnly('hasInterval'), _dec15 = (0, _emberConcurrency.task)(function* () {
    yield this.get('transitioner').perform();
    yield (0, _emberConcurrency.timeout)(this.get('interval'));
    this.toAppropriateSlide();
  }).restartable(), _dec16 = (0, _emberConcurrency.task)(function* () {
    this.set('presentationState', 'willTransit');
    yield (0, _emberConcurrency.timeout)(this.get('transitionDuration'));
    this.set('presentationState', 'didTransition');
    // Must change current index after execution of 'presentationStateObserver' method
    // from child components.
    yield new Ember.RSVP.Promise(resolve => {
      Ember.run.schedule('afterRender', this, function () {
        this.set('currentIndex', this.get('followingIndex'));
        resolve();
      });
    });
  }).drop(), _dec17 = (0, _emberConcurrency.task)(function* () {
    if (this.get('shouldRunAutomatically') === false) {
      return;
    }
    yield (0, _emberConcurrency.timeout)(this.get('interval'));
    this.toAppropriateSlide();
  }).restartable(), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class Carousel extends Ember.Component.extend(_componentParent.default) {
    constructor() {
      super(...arguments);
      this.tabindex = '1';
      /**
       * @property slideComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "slideComponent", _descriptor, this);
      /**
       * All `CarouselSlide` child components.
       *
       * @private
       * @property childSlides
       * @readonly
       * @type array
       */
      (0, _initializerDefineProperty2.default)(this, "childSlides", _descriptor2, this);
      /**
       * Indicates the current index of the current slide.
       *
       * @property currentIndex
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "currentIndex", _descriptor3, this);
      /**
       * Bootstrap style to indicate that a given slide should be moving to left/right.
       *
       * @property directionalClassName
       * @private
       * @type string
       */
      (0, _initializerDefineProperty2.default)(this, "directionalClassName", _descriptor4, this);
      /**
       * Indicates the next slide index to move into.
       *
       * @property followingIndex
       * @private
       * @type number
       */
      (0, _initializerDefineProperty2.default)(this, "followingIndex", _descriptor5, this);
      /**
       * @private
       * @property hasInterval
       * @type boolean
       */
      (0, _initializerDefineProperty2.default)(this, "hasInterval", _descriptor6, this);
      /**
       * If user is hovering its cursor on component.
       * This property is only manipulated when 'pauseOnMouseEnter' is true.
       *
       * @property isMouseHovering
       * @private
       * @type boolean
       */
      (0, _initializerDefineProperty2.default)(this, "isMouseHovering", _descriptor7, this);
      /**
       * The class name to append to the next control link element.
       *
       * @property nextControlClassName
       * @type string
       * @private
       */
      /**
       * Bootstrap style to indicate the next/previous slide.
       *
       * @property orderClassName
       * @private
       * @type string
       */
      /**
       * The current state of the current presentation, can be either "didTransition"
       * or "willTransit".
       *
       * @private
       * @property presentationState
       * @type string
       */
      (0, _initializerDefineProperty2.default)(this, "presentationState", _descriptor8, this);
      /**
       * The class name to append to the previous control link element.
       *
       * @property prevControlClassName
       * @type string
       * @private
       */
      /**
       * @private
       * @property shouldNotDoPresentation
       * @type boolean
       */
      (0, _initializerDefineProperty2.default)(this, "shouldNotDoPresentation", _descriptor9, this);
      /**
       * @private
       * @property shouldRunAutomatically
       * @type boolean
       */
      (0, _initializerDefineProperty2.default)(this, "shouldRunAutomatically", _descriptor10, this);
      /**
       * Starts automatic sliding on page load.
       * This parameter has no effect if interval is less than or equal to zero.
       *
       * @default false
       * @property autoPlay
       * @public
       * @type boolean
       */
      (0, _initializerDefineProperty2.default)(this, "autoPlay", _descriptor11, this);
      /**
       * If false will hard stop on corners, i.e., first slide won't make a transition to the
       * last slide and vice versa.
       *
       * @default true
       * @property wrap
       * @public
       * @type boolean
       */
      (0, _initializerDefineProperty2.default)(this, "wrap", _descriptor12, this);
      /**
       * Index of starting slide.
       *
       * @default 0
       * @property index
       * @public
       * @type number
       */
      (0, _initializerDefineProperty2.default)(this, "index", _descriptor13, this);
      /**
       * Waiting time before automatically show another slide.
       * Automatic sliding is canceled if interval is less than or equal to zero.
       *
       * @default 5000
       * @property interval
       * @public
       * @type number
       */
      (0, _initializerDefineProperty2.default)(this, "interval", _descriptor14, this);
      /**
       * Should bind keyboard events into sliding.
       *
       * @default true
       * @property keyboard
       * @public
       * @type boolean
       */
      (0, _initializerDefineProperty2.default)(this, "keyboard", _descriptor15, this);
      /**
       * If automatic sliding should be left-to-right or right-to-left.
       * This parameter has no effect if interval is less than or equal to zero.
       *
       * @default true
       * @property ltr
       * @public
       * @type boolean
       */
      (0, _initializerDefineProperty2.default)(this, "ltr", _descriptor16, this);
      /**
       * The next control icon to be displayed.
       *
       * @default null
       * @property nextControlIcon
       * @type string
       * @public
       */
      /**
       * Label for screen readers, defaults to 'Next'.
       *
       * @default 'Next'
       * @property nextControlLabel
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "nextControlLabel", _descriptor17, this);
      /**
       * Pauses automatic sliding if mouse cursor is hovering the component.
       * This parameter has no effect if interval is less than or equal to zero.
       *
       * @default true
       * @property pauseOnMouseEnter
       * @public
       * @type boolean
       */
      (0, _initializerDefineProperty2.default)(this, "pauseOnMouseEnter", _descriptor18, this);
      /**
       * The previous control icon to be displayed.
       *
       * @default null
       * @property prevControlIcon
       * @type string
       * @public
       */
      /**
       * Label for screen readers, defaults to 'Previous'.
       *
       * @default 'Previous'
       * @property prevControlLabel
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "prevControlLabel", _descriptor19, this);
      /**
       * Show or hide controls.
       *
       * @default true
       * @property showControls
       * @public
       * @type boolean
       */
      (0, _initializerDefineProperty2.default)(this, "showControls", _descriptor20, this);
      /**
       * Show or hide indicators.
       *
       * @default true
       * @property showIndicators
       * @public
       * @type boolean
       */
      (0, _initializerDefineProperty2.default)(this, "showIndicators", _descriptor21, this);
      /**
       * The duration of the slide transition.
       * You should also change this parameter in Bootstrap CSS file.
       *
       * @default 600
       * @property transitionDuration
       * @public
       * @type number
       */
      (0, _initializerDefineProperty2.default)(this, "transitionDuration", _descriptor22, this);
      /**
       * The type slide transition to perform.
       * Options are 'fade' or 'slide'. Note: BS4 only
       *
       * @default 'slide'
       * @property transition
       * @public
       * @type string
       */
      (0, _initializerDefineProperty2.default)(this, "transition", _descriptor23, this);
      /**
       * Do a presentation and calls itself to perform a cycle.
       *
       * @method cycle
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "cycle", _descriptor24, this);
      /**
       * @method transitioner
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "transitioner", _descriptor25, this);
      /**
       * Waits an interval time to start a cycle.
       *
       * @method waitIntervalToInitCycle
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "waitIntervalToInitCycle", _descriptor26, this);
    }
    /**
     * If a slide can turn to left, including corners.
     *
     * @private
     * @property canTurnToLeft
     */
    get canTurnToLeft() {
      return this.get('wrap') || this.get('currentIndex') > 0;
    }

    /**
     * If a slide can turn to right, including corners.
     *
     * @private
     * @property canTurnToRight
     */
    get canTurnToRight() {
      return this.get('wrap') || this.get('currentIndex') < this.get('childSlides.length') - 1;
    }
    /**
     * This observer is the entry point for real time insertion and removing of slides.
     *
     * @private
     * @property childSlidesObserver
     */
    childSlidesObserver() {
      Ember.run.scheduleOnce('actions', () => {
        let childSlides = this.get('childSlides');
        if (childSlides.length === 0) {
          return;
        }
        // Sets new current index
        let currentIndex = this.get('currentIndex');
        if (currentIndex >= childSlides.length) {
          currentIndex = childSlides.length - 1;
          this.set('currentIndex', currentIndex);
        }
        // Automatic sliding
        if (this.get('autoPlay')) {
          this.get('waitIntervalToInitCycle').perform();
        }
        // Initial slide state
        this.set('presentationState', null);
      });
    }
    /**
     * The current slide object that is going to be used by the nested slides components.
     *
     * @property currentSlide
     * @private
     *
     */
    get currentSlide() {
      return this.get('childSlides').objectAt(this.get('currentIndex'));
    }
    /**
     * The following slide object that is going to be used by the nested slides components.
     *
     * @property followingIndex
     * @private
     */
    get followingSlide() {
      return this.get('childSlides').objectAt(this.get('followingIndex'));
    }
    /**
     * This observer is the entry point for programmatically slide changing.
     *
     * @property indexObserver
     * @private
     */
    indexObserver() {
      this.send('toSlide', this.get('index'));
    }

    /**
     * @property indicators
     * @private
     */
    get indicators() {
      return [...Array(this.get('childSlides.length'))];
    }
    toSlide(toIndex) {
      if (this.get('currentIndex') === toIndex || this.get('shouldNotDoPresentation')) {
        return;
      }
      this.assignClassNameControls(toIndex);
      this.setFollowingIndex(toIndex);
      if (this.get('shouldRunAutomatically') === false || this.get('isMouseHovering')) {
        this.get('transitioner').perform();
      } else {
        this.get('cycle').perform();
      }
    }
    toNextSlide() {
      if (this.get('canTurnToRight')) {
        this.send('toSlide', this.get('currentIndex') + 1);
      }
    }
    toPrevSlide() {
      if (this.get('canTurnToLeft')) {
        this.send('toSlide', this.get('currentIndex') - 1);
      }
    }

    /**
     * Indicates what class names should be applicable to the current transition slides.
     *
     * @method assignClassNameControls
     * @private
     */
    assignClassNameControls(toIndex) {
      if (toIndex < this.get('currentIndex')) {
        this.set('directionalClassName', 'right');
        this.set('orderClassName', 'prev');
      } else {
        this.set('directionalClassName', 'left');
        this.set('orderClassName', 'next');
      }
    }

    /**
     * Initial page loading configuration.
     */
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.registerEvents();
      this.triggerChildSlidesObserver();
    }

    /**
     * mouseEnter() and mouseLeave() doesn't work with ember-native-dom-event-dispatcher.
     *
     * @method registerEvents
     * @private
     */
    registerEvents() {
      let self = this;
      this.element.addEventListener('mouseenter', function () {
        if (self.get('pauseOnMouseEnter')) {
          self.set('isMouseHovering', true);
          self.get('cycle').cancelAll();
          self.get('waitIntervalToInitCycle').cancelAll();
        }
      });
      this.element.addEventListener('mouseleave', function () {
        if (self.get('pauseOnMouseEnter') && (self.get('transitioner.last') !== null || self.get('waitIntervalToInitCycle.last') !== null)) {
          self.set('isMouseHovering', false);
          self.get('waitIntervalToInitCycle').perform();
        }
      });
    }
    keyDown(e) {
      let code = e.keyCode || e.which;
      if (this.get('keyboard') === false || /input|textarea/i.test(e.target.tagName)) {
        return;
      }
      switch (code) {
        case 37:
          this.send('toPrevSlide');
          break;
        case 39:
          this.send('toNextSlide');
          break;
        default:
          break;
      }
    }

    /**
     * Sets the following slide index within the lower and upper bounds.
     *
     * @method setFollowingIndex
     * @private
     */
    setFollowingIndex(toIndex) {
      let slidesLengthMinusOne = this.get('childSlides').length - 1;
      if (toIndex > slidesLengthMinusOne) {
        this.set('followingIndex', 0);
      } else if (toIndex < 0) {
        this.set('followingIndex', slidesLengthMinusOne);
      } else {
        this.set('followingIndex', toIndex);
      }
    }

    /**
     * Coordinates the correct slide movement direction.
     *
     * @method toAppropriateSlide
     * @private
     */
    toAppropriateSlide() {
      if (this.get('ltr')) {
        this.send('toNextSlide');
      } else {
        this.send('toPrevSlide');
      }
    }

    /**
     * @method triggerChildSlidesObserver
     * @private
     */
    triggerChildSlidesObserver() {
      this.get('childSlides');
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "slideComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-carousel/slide';
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "canTurnToLeft", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "canTurnToLeft"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "canTurnToRight", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "canTurnToRight"), _class2.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "childSlides", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "childSlidesObserver", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "childSlidesObserver"), _class2.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "currentIndex", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.get('index');
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "currentSlide", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "currentSlide"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "directionalClassName", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "followingIndex", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "followingSlide", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "followingSlide"), _class2.prototype), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasInterval", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "indexObserver", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "indexObserver"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "indicators", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "indicators"), _class2.prototype), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isMouseHovering", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "presentationState", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "shouldNotDoPresentation", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "shouldRunAutomatically", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "autoPlay", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "wrap", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "index", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "interval", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 5000;
    }
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "keyboard", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "ltr", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "nextControlLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Next';
    }
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "pauseOnMouseEnter", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "prevControlLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Previous';
    }
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showControls", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showIndicators", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "transitionDuration", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 600;
    }
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "transition", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'slide';
    }
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "cycle", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "transitioner", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "waitIntervalToInitCycle", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toSlide", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "toSlide"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toNextSlide", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "toNextSlide"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toPrevSlide", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "toPrevSlide"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = Carousel;
});