define("ember-bootstrap/components/bs-nav", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/components/base/bs-nav", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _bsNav, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2, _descriptor;
  let Nav = (_dec = (0, _component.classNameBindings)('stacked:flex-column', 'fill:nav-fill'), _dec(_class = (_class2 = class Nav extends _bsNav.default {
    constructor() {
      super(...arguments);
      /**
        * Make the nav flex fill, see [bootstrap docs](http://getbootstrap.com/docs/4.1/components/navs/#fill-and-justify)
        *
        * @property fill
        * @type boolean
        * @default false
        * @public
        */
      (0, _initializerDefineProperty2.default)(this, "fill", _descriptor, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fill", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class2)) || _class);
  _exports.default = Nav;
});