define("ember-bootstrap/components/bs-nav/item", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-nav/item"], function (_exports, _component, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let NavItem = (_dec = (0, _component.classNames)('nav-item'), _dec(_class = class NavItem extends _item.default {
    constructor() {
      super(...arguments);
      this.linkClass = 'nav-link';
    }
  }) || _class);
  _exports.default = NavItem;
});