define("ember-bootstrap/components/base/bs-form/element/layout/vertical", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/layout", "ember-bootstrap/templates/components/bs-form/element/layout/vertical"], function (_exports, _component, _layout, _vertical) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  /**
  
   @class FormElementLayoutVertical
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  let FormElementLayoutVertical = (_dec = (0, _component.layout)(_vertical.default), _dec(_class = class FormElementLayoutVertical extends _layout.default {}) || _class);
  _exports.default = FormElementLayoutVertical;
});