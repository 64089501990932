define("ember-google-maps/helpers/g-map/compute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.gMapCompute = gMapCompute;
  // Same as ember-composable-helpers 'compute'.
  function gMapCompute(_ref) {
    let [action, ...params] = _ref;
    return action(...params);
  }
  var _default = Ember.Helper.helper(gMapCompute);
  _exports.default = _default;
});