define("ember-bootstrap/templates/components/bs-popover/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vwOIsCpE",
    "block": "{\"symbols\":[\"@title\",\"@destinationElement\",\"@id\",\"&attrs\",\"&default\"],\"statements\":[[5,\"ember-popper\",[[13,4]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@id\",\"@class\"],[[23,0,[\"ariaRole\"]],[23,0,[\"placement\"]],[23,0,[\"renderInPlace\"]],[23,0,[\"popperTarget\"]],[23,0,[\"popperModifiers\"]],[23,2,[]],[23,0,[\"updatePlacement\"]],[23,0,[\"updatePlacement\"]],[23,3,[]],[23,0,[\"popperClass\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[23,0,[\"arrowClass\"]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasTitle\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[11,\"class\",[23,0,[\"titleClass\"]]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[11,\"class\",[23,0,[\"contentClass\"]]],[8],[14,5],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-popover/element.hbs"
    }
  });
  _exports.default = _default;
});