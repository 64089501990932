define("ember-google-maps/templates/components/g-map/marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7GR4ZCDg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"infoWindow\"],[[28,\"component\",[[24,[\"gMap\",\"infoWindow\"]]],[[\"target\"],[[24,[\"mapComponent\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/marker.hbs"
    }
  });
  _exports.default = _default;
});