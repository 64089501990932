define("ember-bootstrap/components/base/bs-form/element", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/templates/components/bs-form/element", "ember-bootstrap/components/bs-form/group", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _object, _element, _group, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36;
  const nonDefaultLayouts = Ember.A(['checkbox']);

  /**
    Sub class of `Components.FormGroup` that adds automatic form layout markup and form validation features.
  
    ### Form layout
  
    The appropriate Bootstrap markup for the given `formLayout` and `controlType` is automatically generated to easily
    create forms without coding the default Bootstrap form markup by hand:
  
    ```handlebars
    <BsForm @formLayout="horizontal" @model={{this}} @onSubmit={{action "submit"}} as |form|>
      <form.element @controlType="email" @label="Email" @placeholder="Email" @value={{this.email}}" />
      <form.element @controlType="password" @label="Password" @placeholder="Password" @value={{this.password}} />
      <form.element @controlType="checkbox" @label="Remember me" @value={{this.rememberMe}} />
      <BsButton @defaultText="Submit" @type="primary" @buttonType="submit" />
    </BsForm>
    ```
  
    ### Control types
  
    The following control types are supported out of the box:
  
    * Inputs (simple `text`, or any other HTML5 supported input types like `password`, `email` etc.)
    * Checkbox (single)
    * Radio Button (group)
    * Textarea
  
    #### Radio Buttons
  
    For a group of mutually exclusive radio buttons to work, you must supply the `options` property with an array of
    options, each of which will be rendered with an appropriate radio button and its label. It can be either a simple array
    of strings or objects. In the latter case, you would have to set `optionLabelPath` to the property, that contains the
    label on these objects.
  
    ```hbs
    <BsForm @model={{this}} @onSubmit={{action "submit"}} as |form|>
      <form.element @controlType="radio" @label="Gender" @options={{this.genderOptions}} @optionLabelPath="title" @property="gender" />
    </BsForm>
    ```
  
    The default layout for radios is stacked, but Bootstrap's inline layout is also supported using the `inline` property
    of the yielded control component:
  
    ```hbs
    <BsForm @model={{this}} @onSubmit={{action "submit"}} as |form|>
      <form.element @controlType="radio" @label="Gender" @options={{this.genderOptions}} @property="gender" as |el|>
        <el.control @inline={{true}} />
      </form.element>
    </BsForm>
    ```
  
    #### Custom controls
  
    Apart from the standard built-in browser controls (see the `controlType` property), you can use any custom control simply
    by invoking the component with a block template. Use whatever control you might want, for example a select-2 component
    (from the [ember-select-2 addon](https://istefo.github.io/ember-select-2)):
  
    ```hbs
    <BsForm @model={{this}} @onSubmit={{action "submit"}} as |form|>
      <form.element @label="Select-2" @property="gender" @useIcons={{false}} as |el|>
        {{select-2 id=el.id content=genderChoices optionLabelPath="label" value=el.value searchEnabled=false}}
      </form.element>
    </BsForm>
    ```
  
    The component yields a hash with the following properties:
    * `control`: the component that would be used for rendering the form control based on the given `controlType`
    * `id`: id to be used for the form control, so it matches the labels `for` attribute
    * `value`: the value of the form element
    * `validation`: the validation state of the element, `null` if no validation is to be shown, otherwise 'success', 'error' or 'warning'
  
    If your custom control does not render an input element, you should set `useIcons` to `false` since bootstrap only supports
    feedback icons with textual `<input class="form-control">` elements.
  
    If you just want to customize the existing control component, you can use the aforementioned yielded `control` component
    to customize that existing component:
  
    ```hbs
    <BsForm @model={{this}} @onSubmit={{action "submit"}} as |form|>
      <form.element @label="Email" @placeholder="Email" @property="email" as |el|>
        <el.control class="input-lg" />
      </form.element>
    </BsForm>
    ```
  
    If you are using the custom control quite often, you should consider writing an integration plugin like
    [`ember-bootstrap-power-select`](https://github.com/kaliber5/ember-bootstrap-power-select).
    To do so, you need to provide a component `{{bs-form/element/control/my-custom-control}}` which extends
    [`Components.FormElementControl`](Components.FormElementControl.html).
  
    ### Form validation
  
    In the following example the control elements of the three form elements value will be bound to the properties
    (given by `property`) of the form's `model`, which in this case is its controller (see `model=this`):
  
    ```handlebars
    <BsForm @formLayout="horizontal" @model={{this}} @onSubmit={{action "submit"}} as |form|>
      <form.element @controlType="email" @label="Email" @placeholder="Email" @property="email" />
      <form.element @controlType="password" @label="Password" @placeholder="Password" @property="password" />
      <form.element @controlType="checkbox" @label="Remember me" @property="rememberMe" />
      <BsButton @defaultText="Submit" @type="primary" @buttonType="submit" />
    </BsForm>
    ```
  
    By using this indirection in comparison to directly binding the `value` property, you get the benefit of automatic
    form validation, given that your `model` has a supported means of validating itself.
    See [Components.Form](Components.Form.html) for details on how to enable form validation.
  
    In the example above the `model` was our controller itself, so the control elements were bound to the appropriate
    properties of our controller. A controller implementing validations on those properties could look like this:
  
    ```js
    import Ember from 'ember';
    import EmberValidations from 'ember-validations';
  
    export default Ember.Controller.extend(EmberValidations,{
      email: null,
      password: null,
      rememberMe: false,
      validations: {
        email: {
          presence: true,
          format: {
            with: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
          }
        },
        password: {
          presence: true,
          length: { minimum: 6, maximum: 10}
        },
        comments: {
          length: { minimum: 5, maximum: 20}
        }
      }
    });
    ```
  
    If the `showValidation` property is `true` (which is automatically the case if a `focusOut` event is captured from the
    control element or the containing `Components.Form` was submitted with its `model` failing validation) and there are
    validation errors for the `model`'s `property`, the appropriate Bootstrap validation markup (see
    http://getbootstrap.com/css/#forms-control-validation) is applied:
  
    * `validation` is set to 'error', which will set the `has-error` CSS class
    * the `errorIcon` feedback icon is displayed if `controlType` is a text field
    * the validation messages are displayed as Bootstrap `help-block`s in BS3 and `form-control-feedback` in BS4
  
    The same applies for warning messages, if the used validation library supports this. (Currently only
    [ember-cp-validations](https://github.com/offirgolan/ember-cp-validations))
  
    As soon as the validation is successful again...
  
    * `validation` is set to 'success', which will set the `has-success` CSS class
    * the `successIcon` feedback icon is displayed if `controlType` is a text field
    * the validation messages are removed
  
    In case you want to display some error or warning message that is independent of the model's validation, for
    example to display a failure message on a login form after a failed authentication attempt (so not coming from
    the validation library), you can use the `customError` or `customWarning` properties to do so.
  
    ### HTML attributes
  
    To set HTML attributes on the control element provided by this component when using the modern angle bracket invocation,
    you can pass them to the yielded `control` component:
  
    ```hbs
    <BsForm @formLayout="horizontal" @model={{this}} @onSubmit={{action "submit"}} as |form|>
    <form.element @controlType="email" @label="Email" @property="email" as |el|>
      <el.control
        placeholder="Email"
        required={{true}}
        multiple={{true}}
        tabIndex={{5}}
      />
    </form.element>
    ...
    </BsForm>
    ```
  
    If you are using the older curly component syntax, you can set them as properties of this component:
  
    ```hbs
    {{#bs-form formLayout="horizontal" model=this onSubmit=(action "submit") as |form|}}
    {{form.element controlType="email" label="Email" property="email"
      placeholder="Email"
      required=true
      multiple=true
      tabIndex=5
    }}
    ...
    {{/bs-form}}
    ```
  
    The following attributes are supported depending on the `controlType`:
  
    <table class="table table-striped">
    <thead>
    <tr>
    <th></th>
    <th>textarea</th>
    <th>checkbox/radio</th>
    <th>all others</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td>accept</td>
    <td></td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>autocapitalize</td>
    <td>✔︎</td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>autocomplete</td>
    <td>✔︎</td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>autocorrect</td>
    <td>✔︎</td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>autofocus</td>
    <td>✔︎</td>
    <td>✔︎</td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>autosave</td>
    <td></td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>cols</td>
    <td>✔︎</td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>disabled</td>
    <td></td>
    <td>✔︎</td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>form</td>
    <td>✔︎</td>
    <td>✔︎</td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>inputmode</td>
    <td></td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>max</td>
    <td></td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>maxlength</td>
    <td>✔︎</td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>min</td>
    <td></td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>minlength</td>
    <td>✔︎</td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>multiple</td>
    <td></td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>name</td>
    <td>✔︎</td>
    <td>✔︎</td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>pattern</td>
    <td></td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>placeholder</td>
    <td>✔︎</td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>readonly</td>
    <td>✔︎</td>
    <td>︎</td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>required</td>
    <td>✔︎</td>
    <td>✔︎</td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>rows</td>
    <td>✔︎</td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>size<br>via <code>controlSize</code> property</td>
    <td></td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>spellcheck</td>
    <td>✔︎</td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>step</td>
    <td></td>
    <td></td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>tabindex</td>
    <td>✔︎</td>
    <td>✔︎</td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>title</td>
    <td>✔︎</td>
    <td>✔︎</td>
    <td>✔︎</td>
    </tr>
    <tr>
    <td>wrap</td>
    <td>✔︎</td>
    <td></td>
    <td></td>
    </tr>
    </tbody>
    </table>
  
    @class FormElement
    @namespace Components
    @extends Components.FormGroup
    @public
  */
  let FormElement = (_dec = (0, _component.layout)(_element.default), _dec2 = (0, _component.classNameBindings)('disabled:disabled', 'required:is-required', 'isValidating'), _dec3 = Ember.computed.notEmpty('label'), _dec4 = Ember.computed.notEmpty('helpText').readOnly(), _dec5 = Ember.computed.gt('errors.length', 0), _dec6 = Ember.computed.gt('warnings.length', 0), _dec7 = Ember.computed.notEmpty('customError'), _dec8 = Ember.computed.notEmpty('customWarning'), _dec9 = Ember.computed('hasCustomError', 'customError', 'hasErrors', 'hasCustomWarning', 'customWarning', 'hasWarnings', 'errors.[]', 'warnings.[]', 'showModelValidation'), _dec10 = Ember.computed.gt('validationMessages.length', 0), _dec11 = Ember.computed.or('showOwnValidation', 'showAllValidations', 'hasCustomError', 'hasCustomWarning'), _dec12 = Ember.computed.or('showOwnValidation', 'showAllValidations'), _dec13 = Ember.computed.and('showValidation', 'hasValidationMessages'), _dec14 = Ember.computed('showValidationOn.[]'), _dec15 = Ember.computed('hasCustomError', 'hasErrors', 'hasCustomWarning', 'hasWarnings', 'hasValidator', 'showValidation', 'showModelValidation', 'isValidating', 'disabled'), _dec16 = Ember.computed.equal('controlComponent', 'bs-form/element/control/input'), _dec17 = Ember.computed('elementId'), _dec18 = Ember.computed('elementId'), _dec19 = Ember.computed('formLayout', 'controlType'), _dec20 = Ember.computed('controlType'), _dec21 = (0, _object.observes)('hasFeedback', 'formLayout'), _dec(_class = _dec2(_class = (_class2 = class FormElement extends _group.default {
    constructor() {
      super(...arguments);
      /**
       * Text to display within a `<label>` tag.
       *
       * You should include a label for every form input cause otherwise screen readers
       * will have trouble with your forms. Use `invisibleLabel` property if you want
       * to hide them.
       *
       * @property label
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "label", _descriptor, this);
      /**
       * Controls label visibility by adding 'sr-only' class.
       *
       * @property invisibleLabel
       * @type boolean
       * @default false
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "invisibleLabel", _descriptor2, this);
      /**
       * @property hasLabel
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "hasLabel", _descriptor3, this);
      /**
       * The type of the control widget.
       * Supported types:
       *
       * * 'text'
       * * 'checkbox'
       * * 'radio'
       * * 'textarea'
       * * any other type will use an input tag with the `controlType` value as the type attribute (for e.g. HTML5 input
       * types like 'email'), and the same layout as the 'text' type
       *
       * @property controlType
       * @type string
       * @default 'text'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "controlType", _descriptor4, this);
      /**
       * The value of the control element is bound to this property. You can bind it to some controller property to
       * get/set the control element's value:
       *
       * ```hbs
       * {{form.element controlType="email" label="Email" placeholder="Email" value=email}}
       * ```
       *
       * Note: you lose the ability to validate this form element by directly binding to its value. It is recommended
       * to use the `property` feature instead.
       *
       *
       * @property value
       * @public
       */
      /**
       The property name of the form element's `model` (by default the `model` of its parent `Components.Form`) that this
       form element should represent. The control element's value will automatically be bound to the model property's
       value.
        Using this property enables form validation on this element.
        @property property
       @type string
       @public
       */
      (0, _initializerDefineProperty2.default)(this, "property", _descriptor5, this);
      /**
       * The model used for validation. Defaults to the parent `Components.Form`'s `model`
       *
       * @property model
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "model", _descriptor6, this);
      /**
       * Show a help text next to the control
       *
       * @property helpText
       * @type {string}
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "helpText", _descriptor7, this);
      /**
       * Only if there is a validator, this property makes all errors to be displayed at once
       * inside a scrollable container.
       *
       * @default false
       * @property showMultipleErrors
       * @public
       * @type {Boolean}
       */
      (0, _initializerDefineProperty2.default)(this, "showMultipleErrors", _descriptor8, this);
      /**
       * Array of options for control types that show a selection (e.g. radio button groups)
       * Can be an array of simple strings or objects. For objects use `optionLabelPath` to specify the path containing the
       * label.
       *
       * @property options
       * @type {Array}
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "options", _descriptor9, this);
      /**
       * Property path (e.g. 'title' or 'related.name') to render the label of an selection option. See `options`.s
       *
       * @property optionLabelPath
       * @type {String}
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "optionLabelPath", _descriptor10, this);
      /**
       * @property hasHelpText
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "hasHelpText", _descriptor11, this);
      /**
       * The array of error messages from the `model`'s validation.
       *
       * @property errors
       * @type array
       * @protected
       */
      (0, _initializerDefineProperty2.default)(this, "errors", _descriptor12, this);
      /**
       * @property hasErrors
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "hasErrors", _descriptor13, this);
      /**
       * The array of warning messages from the `model`'s validation.
       *
       * @property errors
       * @type array
       * @protected
       */
      (0, _initializerDefineProperty2.default)(this, "warnings", _descriptor14, this);
      /**
       * @property hasWarnings
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "hasWarnings", _descriptor15, this);
      /**
       * Show a custom error message that does not come from the model's validation. Will be immediately shown, regardless
       * of any user interaction (i.e. no `focusOut` event required)
       *
       * @property customError
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "customError", _descriptor16, this);
      /**
       * @property hasCustomError
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "hasCustomError", _descriptor17, this);
      /**
       * Show a custom warning message that does not come from the model's validation. Will be immediately shown, regardless
       * of any user interaction (i.e. no `focusOut` event required). If the model's validation has an error then the error
       * will be shown in place of this warning.
       *
       * @property customWarning
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "customWarning", _descriptor18, this);
      /**
       * @property hasCustomWarning
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "hasCustomWarning", _descriptor19, this);
      /**
       * Property for size styling, set to 'lg', 'sm' or 'xs' (the latter only for BS3)
       *
       * @property size
       * @type String
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "size", _descriptor20, this);
      /**
       * @property hasValidationMessages
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "hasValidationMessages", _descriptor21, this);
      /**
       * @property hasValidator
       * @type boolean
       * @default false
       * @protected
       */
      (0, _initializerDefineProperty2.default)(this, "hasValidator", _descriptor22, this);
      /**
       * Set a validating state for async validations
       *
       * @property isValidating
       * @type boolean
       * @default false
       * @protected
       */
      (0, _initializerDefineProperty2.default)(this, "isValidating", _descriptor23, this);
      /**
       * If `true` form validation markup is rendered (requires a validatable `model`).
       *
       * @property showValidation
       * @type boolean
       * @default false
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "showValidation", _descriptor24, this);
      /**
       * @property showOwnValidation
       * @type boolean
       * @default false
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "showOwnValidation", _descriptor25, this);
      /**
       * @property showModelValidations
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "showModelValidation", _descriptor26, this);
      /**
       * @property showValidationMessages
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "showValidationMessages", _descriptor27, this);
      /**
       * Event or list of events which enable form validation markup rendering.
       * Supported events: ['focusout', 'change', 'input']
       *
       * @property showValidationOn
       * @type string|array
       * @default ['focusout']
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "showValidationOn", _descriptor28, this);
      /**
       * True for text field `controlType`s
       *
       * @property useIcons
       * @type boolean
       * @readonly
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "useIcons", _descriptor29, this);
      /**
       * The form layout used for the markup generation (see http://getbootstrap.com/css/#forms):
       *
       * * 'horizontal'
       * * 'vertical'
       * * 'inline'
       *
       * Defaults to the parent `form`'s `formLayout` property.
       *
       * @property formLayout
       * @type string
       * @default 'vertical'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "formLayout", _descriptor30, this);
      /**
       * The Bootstrap grid class for form labels within a horizontal layout form. Defaults to the value of the same
       * property of the parent form. The corresponding grid class for form controls is automatically computed.
       *
       * @property horizontalLabelGridClass
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "horizontalLabelGridClass", _descriptor31, this);
      /**
       * @property formComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "formComponent", _descriptor32, this);
      /**
       * @property errorsComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "errorsComponent", _descriptor33, this);
      /**
       * @property feedbackIconComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "feedbackIconComponent", _descriptor34, this);
      /**
       * @property labelComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "labelComponent", _descriptor35, this);
      /**
       * @property helpTextComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "helpTextComponent", _descriptor36, this);
    }
    /**
     * The array of validation messages (either errors or warnings) from either custom error/warnings or , if we are showing model validation messages, the model's validation
     *
     * @property validationMessages
     * @type array
     * @private
     */
    get validationMessages() {
      if (this.get('hasCustomError')) {
        return Ember.A([this.get('customError')]);
      }
      if (this.get('hasErrors') && this.get('showModelValidation')) {
        return Ember.A(this.get('errors'));
      }
      if (this.get('hasCustomWarning')) {
        return Ember.A([this.get('customWarning')]);
      }
      if (this.get('hasWarnings') && this.get('showModelValidation')) {
        return Ember.A(this.get('warnings'));
      }
      return null;
    }
    /**
     * @property showAllValidations
     * @type boolean
     * @default false
     * @private
     */
    get showAllValidations() {
      return false;
    }
    set showAllValidations(value) {
      if (value === false) {
        this.set('showOwnValidation', false);
      }
      return value;
    }
    /**
     * @property _showValidationOn
     * @type array
     * @readonly
     * @private
     */
    get _showValidationOn() {
      let showValidationOn = this.get('showValidationOn');
      (true && !(Ember.isArray(showValidationOn) || Ember.typeOf(showValidationOn) === 'string') && Ember.assert('showValidationOn must be a String or an Array', Ember.isArray(showValidationOn) || Ember.typeOf(showValidationOn) === 'string'));
      if (Ember.isArray(showValidationOn)) {
        return showValidationOn.map(type => {
          return type.toLowerCase();
        });
      }
      if (typeof showValidationOn.toString === 'function') {
        return [showValidationOn.toLowerCase()];
      }
      return [];
    }

    /**
     * @method showValidationOnHandler
     * @param {Event} event
     * @private
     */
    showValidationOnHandler(_ref) {
      let {
        target,
        type
      } = _ref;
      // Should not do anything if
      if (
      // validations are already shown or
      this.get('showOwnValidation') ||
      // validations should not be shown for this event type or
      this.get('_showValidationOn').indexOf(type) === -1 ||
      // validation should not be shown for this event target

      Ember.isArray(this.get('doNotShowValidationForEventTargets')) && this.get('doNotShowValidationForEventTargets.length') > 0 && [...this.element.querySelectorAll(this.get('doNotShowValidationForEventTargets').join(','))].some(el => el.contains(target))) {
        return;
      }
      this.set('showOwnValidation', true);
    }

    /**
     * Controls if validation should be shown for specified event targets.
     *
     * It expects an array of query selectors. If event target is a children of an event that matches
     * these selectors, an event triggered for it will not trigger validation errors to be shown.
     *
     * By default events fired on elements inside an input group are skipped.
     *
     * If `null` or an empty array is passed validation errors are shown for all events regardless
     * of event target.
     *
     * @property doNotShowValidationForEventTargets
     * @type ?array
     * @public
     */

    /**
     * The validation ("error" (BS3)/"danger" (BS4), "warning", or "success") or null if no validation is to be shown. Automatically computed from the
     * models validation state.
     *
     * @property validation
     * @readonly
     * @type string
     * @private
     */
    get validation() {
      if (!this.get('showValidation') || !this.get('hasValidator') || this.get('isValidating') || this.get('disabled')) {
        return null;
      } else if (this.get('showModelValidation')) {
        /* The display of model validation messages has been triggered */
        return this.get('hasErrors') || this.get('hasCustomError') ? 'error' : this.get('hasWarnings') || this.get('hasCustomWarning') ? 'warning' : 'success';
      } else {
        /* If there are custom errors or warnings these should always be shown */
        return this.get('hasCustomError') ? 'error' : 'warning';
      }
    }
    /**
     * ID for input field and the corresponding label's "for" attribute
     *
     * @property formElementId
     * @type string
     * @private
     */
    get formElementId() {
      return `${this.get('elementId')}-field`;
    }

    /**
     * ID of the helpText, used for aria-describedby attribute of the control element
     *
     * @property ariaDescribedBy
     * @type string
     * @private
     */
    get ariaDescribedBy() {
      return `${this.get('elementId')}-help`;
    }
    /**
     * @property layoutComponent
     * @type {String}
     * @private
     */
    get layoutComponent() {
      const formComponent = this.get('formComponent');
      const formLayout = this.get('formLayout');
      const controlType = this.get('controlType');
      if (nonDefaultLayouts.includes(controlType)) {
        return `${formComponent}/element/layout/${formLayout}/${controlType}`;
      } else {
        return `${formComponent}/element/layout/${formLayout}`;
      }
    }

    /**
     * @property controlComponent
     * @type {String}
     * @private
     */
    get controlComponent() {
      const formComponent = this.get('formComponent');
      const controlType = this.get('controlType');
      const componentName = `${formComponent}/element/control/${controlType}`;
      if (Ember.getOwner(this).hasRegistration(`component:${componentName}`)) {
        return componentName;
      }
      return `${formComponent}/element/control/input`;
    }
    /**
     * Setup validation properties. This method acts as a hook for external validation
     * libraries to overwrite. In case of failed validations the `errors` property should contain an array of error messages.
     *
     * @method setupValidations
     * @private
     */
    setupValidations() {}

    /**
     * Listen for focusOut events from the control element to automatically set `showOwnValidation` to true to enable
     * form validation markup rendering if `showValidationsOn` contains `focusOut`.
     *
     * @event focusOut
     * @private
     */
    focusOut(event) {
      this.showValidationOnHandler(event);
    }

    /**
     * Listen for change events from the control element to automatically set `showOwnValidation` to true to enable
     * form validation markup rendering if `showValidationsOn` contains `change`.
     *
     * @event change
     * @private
     */
    change(event) {
      this.showValidationOnHandler(event);
    }

    /**
     * Listen for input events from the control element to automatically set `showOwnValidation` to true to enable
     * form validation markup rendering if `showValidationsOn` contains `input`.
     *
     * @event input
     * @private
     */
    input(event) {
      this.showValidationOnHandler(event);
    }

    /**
     * The action is called whenever the input value is changed, e.g. by typing text
     *
     * @event onChange
     * @param {String} value The new value of the form control
     * @param {Object} model The form element's model
     * @param {String} property The value of `property`
     * @public
     */
    onChange() {}

    /**
     * Private duplicate of onChange event used for internal state handling between form and it's elements.
     *
     * @event _onChange
     * @private
     */
    _onChange() {}
    init() {
      super.init(...arguments);
      if (this.get('showValidationOn') === null) {
        this.set('showValidationOn', ['focusOut']);
      }
      if (!Ember.isBlank(this.get('property'))) {
        (true && !(this.get('value') === null || this.get('value') === undefined) && Ember.assert('You cannot set both property and value on a form element', this.get('value') === null || this.get('value') === undefined));
        Ember.defineProperty(this, 'value', Ember.computed.alias(`model.${this.get('property')}`));
        this.setupValidations();
      }
    }

    /*
     * adjust feedback icon position
     *
     * Bootstrap documentation:
     *  Manual positioning of feedback icons is required for [...] input groups
     *  with an add-on on the right. [...] For input groups, adjust the right
     *  value to an appropriate pixel value depending on the width of your addon.
     */
    adjustFeedbackIcons() {
      Ember.run.scheduleOnce('afterRender', () => {
        let el = this.get('element');
        let feedbackIcon;
        // validation state icons are only shown if form element has feedback
        if (!this.get('isDestroying') && this.get('hasFeedback')
        // and form group element has
        // an input-group
        && el.querySelector('.input-group')
        // an addon or button on right side
        && el.querySelector('.input-group input + .input-group-addon, .input-group input + .input-group-btn')
        // an icon showing validation state
        && (feedbackIcon = el.querySelector('.form-control-feedback'))) {
          // clear existing adjustment
          feedbackIcon.style.right = '';
          let defaultPosition = 0;
          let match = getComputedStyle(feedbackIcon).right.match(/^(\d+)px$/);
          if (match) {
            defaultPosition = parseInt(match[1]);
          }
          // Bootstrap documentation:
          //  We do not support multiple add-ons (.input-group-addon or .input-group-btn) on a single side.
          // therefore we could rely on having only one input-group-addon or input-group-btn
          let inputGroupWidth = el.querySelector('input + .input-group-addon, input + .input-group-btn').offsetWidth;
          let adjustedPosition = defaultPosition + inputGroupWidth;
          feedbackIcon.style.right = `${adjustedPosition}px`;
        }
      });
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.adjustFeedbackIcons();
    }
    doChange(value) {
      let {
        onChange,
        model,
        property,
        _onChange
      } = this.getProperties('onChange', 'model', 'property', '_onChange');
      onChange(value, model, property);
      _onChange();
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "label", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "invisibleLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasLabel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "controlType", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'text';
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "property", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "model", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "helpText", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showMultipleErrors", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "options", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "optionLabelPath", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasHelpText", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "errors", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasErrors", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "warnings", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasWarnings", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "customError", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasCustomError", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "customWarning", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasCustomWarning", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "validationMessages", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "validationMessages"), _class2.prototype), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasValidationMessages", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasValidator", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isValidating", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showValidation", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showOwnValidation", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showAllValidations", [Ember.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "showAllValidations"), _class2.prototype), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showModelValidation", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showValidationMessages", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showValidationOn", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "_showValidationOn", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "_showValidationOn"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "validation", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "validation"), _class2.prototype), _descriptor29 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "useIcons", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "formLayout", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'vertical';
    }
  }), _descriptor31 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "horizontalLabelGridClass", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "formElementId", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "formElementId"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "ariaDescribedBy", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "ariaDescribedBy"), _class2.prototype), _descriptor32 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "formComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-form';
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "layoutComponent", [_dec19], Object.getOwnPropertyDescriptor(_class2.prototype, "layoutComponent"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "controlComponent", [_dec20], Object.getOwnPropertyDescriptor(_class2.prototype, "controlComponent"), _class2.prototype), _descriptor33 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "errorsComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-form/element/errors';
    }
  }), _descriptor34 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "feedbackIconComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-form/element/feedback-icon';
    }
  }), _descriptor35 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "labelComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-form/element/label';
    }
  }), _descriptor36 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "helpTextComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-form/element/help-text';
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "adjustFeedbackIcons", [_dec21], Object.getOwnPropertyDescriptor(_class2.prototype, "adjustFeedbackIcons"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doChange", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "doChange"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = FormElement;
});