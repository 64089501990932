define("ember-bootstrap/components/bs-navbar", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/initializerWarningHelper", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-bootstrap/components/base/bs-navbar", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _initializerWarningHelper2, _applyDecoratedDescriptor2, _component, _bsNavbar, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;
  let Navbar = (_dec = (0, _component.classNameBindings)('breakpointClass', 'backgroundClass'), _dec2 = Ember.computed('appliedType'), _dec3 = Ember.computed('toggleBreakpoint'), _dec4 = Ember.computed('backgroundColor'), _dec(_class = (_class2 = class Navbar extends _bsNavbar.default {
    constructor() {
      super(...arguments);
      /**
       * Defines the responsive toggle breakpoint size. Options are the standard
       * two character Bootstrap size abbreviations. Used to set the `navbar-expand[-*]`
       * class. Set to `null` to disable collapsing.
       *
       * @property toggleBreakpoint
       * @type String
       * @default 'lg'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "toggleBreakpoint", _descriptor, this);
      /**
       * Sets the background color for the navbar. Can be any color
       * in the set that composes the `bg-*` classes.
       *
       * @property backgroundColor
       * @type String
       * @default 'light'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "backgroundColor", _descriptor2, this);
      this._validPositions = ['fixed-top', 'fixed-bottom', 'sticky-top'];
      this._positionPrefix = '';
    }
    get type() {
      return this.get('appliedType') || 'light';
    }
    set type(value) {
      let newValue = !value || value === 'default' ? 'light' : value;
      this.set('appliedType', newValue);
      return newValue;
    }
    get breakpointClass() {
      let toggleBreakpoint = this.get('toggleBreakpoint');
      if (Ember.isBlank(toggleBreakpoint)) {
        return 'navbar-expand';
      } else {
        return `navbar-expand-${toggleBreakpoint}`;
      }
    }
    get backgroundClass() {
      let backgroundColor = this.get('backgroundColor');
      return `bg-${backgroundColor}`;
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "type", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "type"), _class2.prototype), _descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toggleBreakpoint", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'lg';
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "backgroundColor", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'light';
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "breakpointClass", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "breakpointClass"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "backgroundClass", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "backgroundClass"), _class2.prototype)), _class2)) || _class);
  _exports.default = Navbar;
});