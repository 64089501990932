define("ember-bootstrap/components/base/bs-navbar", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/utils/cp/type-class", "ember-bootstrap/templates/components/bs-navbar", "ember-bootstrap/utils/cp/listen-to", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _object, _typeClass, _bsNavbar, _listenTo, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  /**
    Component to generate [Bootstrap navbars](http://getbootstrap.com/components/#navbar).
  
    ### Usage
  
    Uses the following components by a contextual reference:
  
    ```hbs
    <BsNavbar as |navbar|>
      <div class="navbar-header">
         {{navbar.toggle}}
         <a class="navbar-brand" href="#">Brand</a>
      </div>
      <navbar.content>
        <navbar.nav as |nav|>
          <nav.item>
            <nav.linkTo @route="home">Home</nav.linkTo>
          </nav.item>
          <nav.item>
            <nav.linkTo @route="navbars">Navbars</nav.linkTo>
          </nav.item>
        </navbar.nav>
      </navbar.content>
    </BsNavBar>
    ```
  
    **Note:** the `<div class="navbar-header">` is required for BS3 to hold the elements visible on a mobile breakpoint,
    when the actual content is collapsed. It should *not* be used for BS4!
  
    The component yields references to the following contextual components:
  
    * [Components.NavbarContent](Components.NavbarContent.html)
    * [Components.NavbarToggle](Components.NavbarToggle.html)
    * [Components.NavbarNav](Components.NavbarNav.html)
  
    Furthermore references to the following actions are yielded:
  
    * `collapse`: triggers the `onCollapse` action and collapses the navbar (mobile)
    * `expand`: triggers the `onExpand` action and expands the navbar (mobile)
    * `toggleNavbar`: triggers the `toggleNavbar` action and toggles the navbar (mobile)
  
    ### Responsive Design
  
    For the mobile breakpoint the Bootstrap styles will hide the navbar content (`{{navbar.content}}`). Clicking on the
    navbar toggle button (`{{navbar.toggle}}`) will expand the menu. By default all nav links (`<nav.linkTo @route="...">`) are already
    wired up to call the navbar's `collapse` action, so clicking any of them will collapse the navbar. To selectively
    prevent that, you can set its `collapseNavbar` property to false:
  
    ```hbs
    <nav.item>
      <nav.linkTo @route="index" @collapseNavbar={{false}}>Don't collapse</nav.linkTo>
    </nav.item>
    ```
  
    To collapse the navbar when clicking on some nav items that are not internal links, you can use the yielded `collapse`
    action:
  
    ```hbs
    <BsNavbar as |navbar|>
      <navbar.content>
        <navbar.nav as |nav|>
          <nav.item>
            <a onclick={{action navbar.collapse}}>Collapse</a>
          </nav.item>
        </navbar.nav>
      </navbar.content>
    </BsNavBar>
    ```
  
    ### Navbar styles
  
    The component supports the default bootstrap navbar styling options through the `type`
    property. Bootstrap navbars [do not currently support justified nav links](http://getbootstrap.com/components/#navbar-default),
    so those are explicitly disallowed.
  
    Other bootstrap navbar variations, such as forms, buttons, etc. can be supported through direct use of
    bootstrap styles applied through the `class` attribute on the components.
  
    ### Bootstrap 3/4 Notes
  
    Bootstrap 4 changed the default navbar styling option from `navbar-default` to `navbar-light`.
    If you explicitly specified "default" in Bootstrap 3 and are migrating, you will need to change
    this in your code. Bootstrap 4 changes `navbar-inverse` to `navbar-dark`.
  
    Bootstrap 4 navbars are fluid by default without the need for an additional container. An
    additional container is added like with Bootstrap 3 if `fluid` is `false`.
  
    @class Navbar
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Navbar = (_dec = (0, _component.layout)(_bsNavbar.default), _dec2 = (0, _component.tagName)('nav'), _dec3 = (0, _component.classNames)('navbar'), _dec4 = (0, _component.classNameBindings)('positionClass', 'typeClass'), _dec5 = (0, _listenTo.default)('collapsed'), _dec6 = Ember.computed('position'), _dec7 = (0, _typeClass.default)('navbar', 'type'), _dec8 = (0, _object.observes)('_collapsed'), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class Navbar extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * Manages the state for the responsive menu between the toggle and the content.
       *
       * @property collapsed
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "collapsed", _descriptor, this);
      /**
       * @property _collapsed
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "_collapsed", _descriptor2, this);
      /**
       * Controls whether the wrapping div is a fluid container or not.
       *
       * @property fluid
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "fluid", _descriptor3, this);
      /**
       * Specifies the position classes for the navbar, currently supporting none, "fixed-top", "fixed-bottom", and
       * either "static-top" (BS3) or "sticky-top" (BS4).
       * See the [bootstrap docs](http://getbootstrap.com/components/#navbar-fixed-top) for details.
       *
       * @property position
       * @type String
       * @default null
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "position", _descriptor4, this);
      /**
       * Property for type styling
       *
       * For the available types see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/navbar/#color-schemes)
       *
       * @property type
       * @type String
       * @default 'default'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "typeClass", _descriptor6, this);
      /**
       * Bootstrap 4 Only: Defines the responsive toggle breakpoint size. Options are the standard
       * two character Bootstrap size abbreviations. Used to set the `navbar-expand-*`
       * class. Set to `null` to disable collapsing.
       *
       * @property toggleBreakpoint
       * @type String
       * @default 'lg'
       * @public
       */
      /**
       * Bootstrap 4 Only: Sets the background color for the navbar. Can be any color
       * in the set that composes the `bg-*` classes and can be extended by creating your
       * own `bg-*` classes.
       *
       * @property backgroundColor
       * @type String
       * @default 'light'
       * @public
       */
      /**
       * @property toggleComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "toggleComponent", _descriptor7, this);
      /**
       * @property contentComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "contentComponent", _descriptor8, this);
      /**
       * @property navComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "navComponent", _descriptor9, this);
      /**
       * @property linkToComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "linkToComponent", _descriptor10, this);
    }
    get positionClass() {
      let position = this.get('position');
      let validPositions = this.get('_validPositions');
      let positionPrefix = this.get('_positionPrefix');
      if (validPositions.indexOf(position) === -1) {
        return null;
      }
      return `${positionPrefix}${position}`;
    }
    /**
     * The action to be sent when the navbar is about to be collapsed.
     *
     * You can return false to prevent collapsing the navbar automatically, and do that in your action by
     * setting `collapsed` to true.
     *
     * @event onCollapse
     * @public
     */
    onCollapse() {}

    /**
     * The action to be sent after the navbar has been collapsed (including the CSS transition).
     *
     * @event onCollapsed
     * @public
     */
    onCollapsed() {}

    /**
     * The action to be sent when the navbar is about to be expanded.
     *
     * You can return false to prevent expanding the navbar automatically, and do that in your action by
     * setting `collapsed` to false.
     *
     * @event onExpand
     * @public
     */
    onExpand() {}

    /**
     * The action to be sent after the navbar has been expanded (including the CSS transition).
     *
     * @event onExpanded
     * @public
     */
    onExpanded() {}
    _onCollapsedChange() {
      let collapsed = this.get('_collapsed');
      let active = this.get('active');
      if (collapsed !== active) {
        return;
      }
      if (collapsed === false) {
        this.show();
      } else {
        this.hide();
      }
    }

    /**
     * @method expand
     * @private
     */
    expand() {
      if (this.onExpand() !== false) {
        this.set('_collapsed', false);
      }
    }

    /**
     * @method collapse
     * @private
     */
    collapse() {
      if (this.onCollapse() !== false) {
        this.set('_collapsed', true);
      }
    }
    toggleNavbar() {
      if (this.get('_collapsed')) {
        this.expand();
      } else {
        this.collapse();
      }
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "collapsed", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "_collapsed", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fluid", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "position", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "positionClass", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "positionClass"), _class2.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "typeClass", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "_onCollapsedChange", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "_onCollapsedChange"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "expand", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "expand"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "collapse", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "collapse"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toggleNavbar", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleNavbar"), _class2.prototype), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toggleComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-navbar/toggle';
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "contentComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-navbar/content';
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "navComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-navbar/nav';
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "linkToComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-navbar/link-to';
    }
  })), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = Navbar;
});