define("ember-composability-tools/templates/render-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9mSwgABo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"shouldRender\"]]],null,{\"statements\":[[4,\"in-element\",[[28,\"-clear-element\",[[24,[\"destinationElement\"]]],null]],[[\"guid\",\"guid\",\"nextSibling\"],[\"%cursor:0%\",\"%cursor:0%\",null]],{\"statements\":[[14,1,[[24,[\"yieldHash\"]]]]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-composability-tools/templates/render-block.hbs"
    }
  });
  _exports.default = _default;
});