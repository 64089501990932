define("ember-bootstrap/components/base/bs-popover/element", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/components/base/bs-contextual-help/element", "ember-bootstrap/templates/components/bs-popover/element"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _element, _element2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  /**
   Internal component for popover's markup. Should not be used directly.
  
   @class PopoverElement
   @namespace Components
   @extends Components.ContextualHelpElement
   @private
   */
  let PopoverElement = (_dec = (0, _component.layout)(_element2.default), _dec2 = Ember.computed.notEmpty('title'), _dec(_class = (_class2 = class PopoverElement extends _element.default {
    constructor() {
      super(...arguments);
      /**
       * @property title
       * @type string
       * @public
       */
      /**
       * @property hasTitle
       * @type boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "hasTitle", _descriptor, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasTitle", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = PopoverElement;
});