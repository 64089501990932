define("ember-bootstrap/components/base/bs-progress/bar", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-progress/bar", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _bar, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  /**
  
   Component for a single progress bar, see [Components.Progress](Components.Progress.html) for more examples.
  
   @class ProgressBar
   @namespace Components
   @extends Ember.Component
   @public
   */
  let ProgressBar = (_dec = (0, _component.layout)(_bar.default), _dec2 = (0, _component.classNames)('progress-bar'), _dec3 = (0, _component.classNameBindings)('progressBarStriped', 'typeClass'), _dec4 = (0, _component.attributeBindings)('ariaValuenow', 'ariaValuemin', 'ariaValuemax'), _dec5 = Ember.computed.readOnly('striped'), _dec6 = Ember.computed.readOnly('animate'), _dec7 = Ember.computed.readOnly('value'), _dec8 = Ember.computed.readOnly('minValue'), _dec9 = Ember.computed.readOnly('maxValue'), _dec10 = Ember.computed('value', 'minValue', 'maxValue').readOnly(), _dec11 = Ember.computed('percent', 'roundDigits').readOnly(), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class ProgressBar extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * The lower limit of the value range
       *
       * @property minValue
       * @type number
       * @default 0
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "minValue", _descriptor, this);
      /**
       * The upper limit of the value range
       *
       * @property maxValue
       * @type number
       * @default 100
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "maxValue", _descriptor2, this);
      /**
       * The value the progress bar should represent
       *
       * @property value
       * @type number
       * @default 0
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor3, this);
      /**
       If true a label will be shown inside the progress bar.
        By default it will be the percentage corresponding to the `value` property, rounded to `roundDigits` digits.
       You can customize it by using the component with a block template, which the component yields the percentage
       value to:
        ```hbs
       {{#bs-progress}}
       {{#bs-progress-bar value=progressValue as |percent|}}{{progressValue}} ({{percent}}%){{/bs-progress-bar}}
       {{/bs-progress}}
       ```
        @property showLabel
       @type boolean
       @default false
       @public
       */
      (0, _initializerDefineProperty2.default)(this, "showLabel", _descriptor4, this);
      /**
       * Create a striped effect, see http://getbootstrap.com/components/#progress-striped
       *
       * @property striped
       * @type boolean
       * @default false
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "striped", _descriptor5, this);
      /**
       * Animate the stripes, see http://getbootstrap.com/components/#progress-animated
       *
       * @property animate
       * @type boolean
       * @default false
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "animate", _descriptor6, this);
      /**
       * Specify to how many digits the progress bar label should be rounded.
       *
       * @property roundDigits
       * @type number
       * @default 0
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "roundDigits", _descriptor7, this);
      /**
       * Property for type styling
       *
       * For the available types see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/progress/#backgrounds)
       *
       * @property type
       * @type String
       * @default 'default'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "progressBarStriped", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "progressBarAnimate", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "ariaValuenow", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "ariaValuemin", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "ariaValuemax", _descriptor13, this);
    }
    /**
     * The percentage of `value`
     *
     * @property percent
     * @type number
     * @protected
     * @readonly
     */
    get percent() {
      let value = parseFloat(this.get('value'));
      let minValue = parseFloat(this.get('minValue'));
      let maxValue = parseFloat(this.get('maxValue'));
      return Math.min(Math.max((value - minValue) / (maxValue - minValue), 0), 1) * 100;
    }

    /**
     * The percentage of `value`, rounded to `roundDigits` digits
     *
     * @property percentRounded
     * @type number
     * @protected
     * @readonly
     */
    get percentRounded() {
      let roundFactor = Math.pow(10, this.get('roundDigits'));
      return Math.round(this.get('percent') * roundFactor) / roundFactor;
    }

    /**
     * @method updateStyles
     * @return void
     * @private
     */
    updateStyles() {
      let percent = parseFloat(this.get('percent'));
      this.element.style.width = !isNaN(percent) ? `${percent}%` : '';
    }
    didInsertElement() {
      this.updateStyles();
    }
    didUpdateAttrs() {
      this.updateStyles();
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "minValue", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "maxValue", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 100;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "value", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "striped", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "animate", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "roundDigits", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "progressBarStriped", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "progressBarAnimate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "ariaValuenow", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "ariaValuemin", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "ariaValuemax", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "percent", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "percent"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "percentRounded", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "percentRounded"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = ProgressBar;
});