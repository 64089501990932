define("ember-google-maps/utils/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computedPromise = computedPromise;
  _exports.position = void 0;
  let ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  let position = Ember.computed('lat', 'lng', function () {
    const {
      lat,
      lng
    } = Ember.getProperties(this, 'lat', 'lng');
    if (lat && lng) {
      return new google.maps.LatLng(lat, lng);
    }
  });
  _exports.position = position;
  function computedPromise() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    let func = args.pop();
    return Ember.computed(...args, function () {
      return ObjectPromiseProxy.create({
        promise: func.apply(this)
      });
    });
  }
});