define("ember-bootstrap/components/base/bs-form/element/control/radio", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/control", "ember-bootstrap/templates/components/bs-form/element/control/radio", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _control, _radio, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  /**
  
   @class FormElementControlRadio
   @namespace Components
   @extends Components.FormElementControl
   @private
   */
  let FormElementControlRadio = (_dec = (0, _component.layout)(_radio.default), _dec2 = (0, _component.tagName)(''), _dec(_class = _dec2(_class = (_class2 = class FormElementControlRadio extends _control.default {
    constructor() {
      super(...arguments);
      /**
       * @property inline
       * @type {Boolean}
       * @default false
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "inline", _descriptor, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "inline", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class2)) || _class) || _class);
  _exports.default = FormElementControlRadio;
});