define("ember-bootstrap/components/bs-carousel", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/components/base/bs-carousel", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _bsCarousel, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  let Carousel = (_dec = (0, _component.classNameBindings)('carouselFade'), _dec2 = Ember.computed.equal('transition', 'fade').readOnly(), _dec(_class = (_class2 = class Carousel extends _bsCarousel.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "nextControlClassName", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "nextControlIcon", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "prevControlClassName", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "prevControlIcon", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "carouselFade", _descriptor5, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "nextControlClassName", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'carousel-control-next';
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "nextControlIcon", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'carousel-control-next-icon';
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "prevControlClassName", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'carousel-control-prev';
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "prevControlIcon", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'carousel-control-prev-icon';
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "carouselFade", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = Carousel;
});