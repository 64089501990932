define("ember-bootstrap/components/bs-form/element/layout/horizontal", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-bootstrap/components/base/bs-form/element/layout/horizontal"], function (_exports, _applyDecoratedDescriptor2, _horizontal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let FormElementLayoutHorizontal = (_dec = Ember.computed('horizontalLabelGridClass'), (_class = class FormElementLayoutHorizontal extends _horizontal.default {
    /**
     * Computed property that specifies the Bootstrap offset grid class for form controls within a horizontal layout
     * form, that have no label.
     *
     * @property horizontalInputOffsetGridClass
     * @type string
     * @readonly
     * @private
     */
    get horizontalInputOffsetGridClass() {
      if (Ember.isBlank(this.get('horizontalLabelGridClass'))) {
        return undefined;
      }
      let parts = this.get('horizontalLabelGridClass').split('-');
      parts.splice(0, 1, 'offset');
      return parts.join('-');
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "horizontalInputOffsetGridClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "horizontalInputOffsetGridClass"), _class.prototype)), _class));
  _exports.default = FormElementLayoutHorizontal;
});