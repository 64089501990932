define("ember-bootstrap/templates/components/bs-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "03/hplCo",
    "block": "{\"symbols\":[\"Element\",\"@title\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[1,[28,\"unbound\",[[24,[\"_parentFinder\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,0,[\"inDom\"]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[[23,0,[\"elementComponent\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[[13,4]],[[\"@placement\",\"@fade\",\"@showHelp\",\"@title\",\"@renderInPlace\",\"@popperTarget\",\"@destinationElement\",\"@autoPlacement\",\"@viewportElement\",\"@viewportPadding\",\"@id\",\"@class\"],[[23,0,[\"placement\"]],[23,0,[\"fade\"]],[23,0,[\"showHelp\"]],[23,2,[]],[23,0,[\"_renderInPlace\"]],[23,0,[\"triggerTargetElement\"]],[23,0,[\"destinationElement\"]],[23,0,[\"autoPlacement\"]],[23,0,[\"viewportElement\"]],[23,0,[\"viewportPadding\"]],[23,0,[\"overlayId\"]],[23,3,[]]]],{\"statements\":[[0,\"\\n      \"],[14,5,[[28,\"hash\",null,[[\"close\"],[[23,0,[\"close\"]]]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-popover.hbs"
    }
  });
  _exports.default = _default;
});