define("ember-bootstrap/components/bs-form/element/label", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/initializerWarningHelper", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/label", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _initializerWarningHelper2, _applyDecoratedDescriptor2, _component, _label, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor;
  let FormElementLabel = (_dec = (0, _component.tagName)('label'), _dec2 = (0, _component.classNameBindings)('invisibleLabel:sr-only', 'isHorizontalAndNotCheckbox:col-form-label', 'isCheckbox:form-check-label', 'labelClass', 'sizeClass'), _dec3 = (0, _component.attributeBindings)('formElementId:for'), _dec4 = Ember.computed('isHorizontal', 'isCheckbox'), _dec5 = Ember.computed('size', 'isHorizontal'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class FormElementLabel extends _label.default {
    constructor() {
      super(...arguments);
      /**
       * Property for size styling, set to 'lg', 'sm'
       *
       * @property size
       * @type String
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "size", _descriptor, this);
    }
    get isHorizontalAndNotCheckbox() {
      return this.get('isHorizontal') && !this.get('isCheckbox');
    }
    get sizeClass() {
      if (!this.get('isHorizontal')) {
        return undefined;
      }
      let size = this.get('size');
      return Ember.isBlank(size) ? null : `col-form-label-${size}`;
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isHorizontalAndNotCheckbox", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isHorizontalAndNotCheckbox"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sizeClass", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "sizeClass"), _class2.prototype), _descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class2)) || _class) || _class) || _class);
  _exports.default = FormElementLabel;
});