define("ember-bootstrap/components/bs-dropdown", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-dropdown"], function (_exports, _component, _bsDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let Dropdown = (_dec = (0, _component.classNameBindings)('inNav:nav-item', 'isOpen:show'), _dec(_class = class Dropdown extends _bsDropdown.default {}) || _class);
  _exports.default = Dropdown;
});