define("ember-bootstrap/components/bs-form/element/control/checkbox", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/control/checkbox", "ember-bootstrap/utils/cp/form-validation-class"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _checkbox, _formValidationClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor;
  let FormElementControlCheckbox = (_dec = (0, _component.classNames)('form-check-input'), _dec2 = (0, _component.classNameBindings)('formValidationClass'), _dec3 = (0, _formValidationClass.default)('validationType'), _dec(_class = _dec2(_class = (_class2 = class FormElementControlCheckbox extends _checkbox.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "formValidationClass", _descriptor, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "formValidationClass", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = FormElementControlCheckbox;
});