define("ember-bootstrap/components/base/bs-dropdown/toggle", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor;
  /**
   Anchor element that triggers the parent dropdown to open.
   Use [Components.DropdownButton](Components.DropdownButton.html) if you want a button instead of an anchor tag.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownToggle
   @namespace Components
   @extends Ember.Component
   @publicø
   */
  let DropdownToggle = (_dec = (0, _component.classNames)('dropdown-toggle'), _dec2 = (0, _component.tagName)('a'), _dec3 = (0, _component.attributeBindings)('href'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class DropdownToggle extends Ember.Component {
    constructor() {
      super(...arguments);
      this.ariaRole = 'button';
      /**
       * @property inNav
       * @type {boolean}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "inNav", _descriptor, this);
    }
    /**
     * Computed property to generate a `href="#"` attribute when `tagName` is "a".
     *
     * @property href
     * @type string
     * @readonly
     * @private
     */
    get href() {
      return this.get('tagName').toUpperCase() === 'A' ? '#' : undefined;
    }

    /**
     * When clicking the toggle this action is called.
     *
     * @event onClick
     * @param {*} value
     * @public
     */
    onClick() {}
    click(e) {
      e.preventDefault();
      this.get('onClick')();
    }
    didReceiveAttrs() {
      // super.didReceiveAttrs(...arguments);
      let dropdown = this.get('dropdown');
      if (dropdown) {
        Ember.run.schedule('actions', this, function () {
          if (!this.get('isDestroyed')) {
            dropdown.set('toggle', this);
          }
        });
      }
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "inNav", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class2)) || _class) || _class) || _class);
  _exports.default = DropdownToggle;
});