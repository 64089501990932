define("ember-syfl-blueprints/initializers/component-namespace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /**
   * This initializer reopens components and injects an NS variable which is
   * the component's name. It also adds this value to the component's classNames.
   */

  function initialize( /* application */
  ) {
    Ember.Component.reopen({
      init() {
        this._super(...arguments);
        try {
          // Gets the name of the component
          const componentPath = this.toString().match(/@component:.+?:/);

          // Get a potential explicitly set NS value
          const NS = this.get('NS');
          let className = componentPath ? componentPath[0].split(':')[1] : null;

          // If NS is explicitly set, assign it's value to classNames
          if (NS) {
            className = NS;
          } else {
            const cn = className;

            // If the component is nested...
            if (~cn.lastIndexOf('/')) {
              // ... it contains a '/', so we need to split it
              className = cn.substring(cn.lastIndexOf('/') + 1, cn.length);
            }

            // Set the NS value to the component
            this.set('NS', className);
          }

          // Add NS to the components' `classNames`
          const classNames = this.classNames.concat(className);
          this.set('classNames', classNames);
        } catch (err) {
          return;
        }
      }
    });
  }
  var _default = {
    name: 'component-namespace',
    initialize
  };
  _exports.default = _default;
});