define("ember-bootstrap/utils/cp/form-validation-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formValidationClass;
  function formValidationClass(validationTypeProperty) {
    (true && !(typeof validationTypeProperty === 'string') && Ember.assert('formValidationClass needs validationTypeProperty argument', typeof validationTypeProperty === 'string'));
    return Ember.computed('validationType', function () {
      let validationType = this.get(validationTypeProperty);
      switch (validationType) {
        case 'error':
          return 'is-invalid';
        case 'success':
          return 'is-valid';
        case 'warning':
          return 'is-warning';
        // not officially supported in BS4 :(
      }
    });
  }
});