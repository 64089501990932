define("ember-bootstrap/components/base/bs-contextual-help/element", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-tooltip/element", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _element, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  /**
   Internal (abstract) component for contextual help markup. Should not be used directly.
  
   @class ContextualHelpElement
   @namespace Components
   @extends Ember.Component
   @private
   */
  let ContextualHelpElement = (_dec = (0, _component.layout)(_element.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.computed.reads('placement'), _dec4 = Ember.computed('popperClassNames.[]', 'class'), _dec5 = Ember.computed('arrowClass', 'autoPlacement', 'viewportElement', 'viewportPadding'), _dec(_class = _dec2(_class = (_class2 = class ContextualHelpElement extends Ember.Component {
    constructor() {
      super(...arguments);
      this.ariaRole = 'tooltip';
      /**
       * @property placement
       * @type string
       * @default 'top'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "placement", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "actualPlacement", _descriptor2, this);
      /**
       * @property fade
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "fade", _descriptor3, this);
      /**
       * @property showHelp
       * @type boolean
       * @default false
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "showHelp", _descriptor4, this);
      /**
       * If true component will render in place, rather than be wormholed.
       *
       * @property renderInPlace
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "renderInPlace", _descriptor5, this);
      /**
       * Which element to align to
       *
       * @property popperTarget
       * @type {string|HTMLElement}
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "popperTarget", _descriptor6, this);
      /**
       * @property autoPlacement
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "autoPlacement", _descriptor7, this);
      /**
       * The DOM element of the viewport element.
       *
       * @property viewportElement
       * @type object
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "viewportElement", _descriptor8, this);
      /**
       * Take a padding into account for keeping the tooltip/popover within the bounds of the element given by `viewportElement`.
       *
       * @property viewportPadding
       * @type number
       * @default 0
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "viewportPadding", _descriptor9, this);
      /**
       * @property arrowClass
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "arrowClass", _descriptor10, this);
    }
    /**
     * @property popperClassNames
     * @type {array}
     * @private
     */
    /**
     * @property popperClass
     * @type {string}
     * @private
     */
    get popperClass() {
      let classes = this.get('popperClassNames');
      let classProperty = this.get('class');
      if (typeof classProperty === 'string') {
        classes = classes.concat(classProperty.split(' '));
      }
      return classes.join(' ');
    }

    /**
     * popper.js modifier config
     *
     * @property popperModifiers
     * @type {object}
     * @private
     */
    get popperModifiers() {
      let self = this;
      return {
        arrow: {
          element: `.${this.get('arrowClass')}`
        },
        offset: {
          fn(data) {
            let tip = document.getElementById(self.get('id'));
            (true && !(tip) && Ember.assert('Contextual help element needs existing popper element', tip)); // manually read margins because getBoundingClientRect includes difference
            let marginTop = parseInt(window.getComputedStyle(tip).marginTop, 10);
            let marginLeft = parseInt(window.getComputedStyle(tip).marginLeft, 10);

            // we must check for NaN for ie 8/9
            if (isNaN(marginTop) || marginTop > 0) {
              marginTop = 0;
            }
            if (isNaN(marginLeft) || marginLeft > 0) {
              marginLeft = 0;
            }
            data.offsets.popper.top += marginTop;
            data.offsets.popper.left += marginLeft;
            return window.Popper.Defaults.modifiers.offset.fn.apply(this, arguments);
          }
        },
        preventOverflow: {
          enabled: this.get('autoPlacement'),
          boundariesElement: this.get('viewportElement'),
          padding: this.get('viewportPadding')
        },
        hide: {
          enabled: this.get('autoPlacement')
        },
        flip: {
          enabled: this.get('autoPlacement')
        }
      };
    }
    didReceiveAttrs() {
      (true && !(this.get('id')) && Ember.assert('Contextual help element needs id for popper element', this.get('id')));
    }
    updatePlacement(popperDataObject) {
      if (this.get('actualPlacement') === popperDataObject.placement) {
        return;
      }
      this.set('actualPlacement', popperDataObject.placement);
      Ember.run.scheduleOnce('afterRender', popperDataObject.instance, popperDataObject.instance.scheduleUpdate);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "placement", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'top';
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "actualPlacement", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showHelp", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "renderInPlace", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "popperTarget", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "autoPlacement", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "viewportElement", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "viewportPadding", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "arrowClass", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'arrow';
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "popperClass", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "popperClass"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "popperModifiers", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "popperModifiers"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updatePlacement", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "updatePlacement"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ContextualHelpElement;
});