define("ember-bootstrap/templates/components/bs-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CfyqjnXH",
    "block": "{\"symbols\":[\"Element\",\"@title\",\"&default\",\"@class\",\"&attrs\"],\"statements\":[[1,[28,\"unbound\",[[24,[\"_parentFinder\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,0,[\"inDom\"]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[[23,0,[\"elementComponent\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[[13,5]],[[\"@placement\",\"@fade\",\"@showHelp\",\"@renderInPlace\",\"@destinationElement\",\"@popperTarget\",\"@autoPlacement\",\"@viewportElement\",\"@viewportPadding\",\"@id\",\"@class\"],[[23,0,[\"placement\"]],[23,0,[\"fade\"]],[23,0,[\"showHelp\"]],[23,0,[\"_renderInPlace\"]],[23,0,[\"destinationElement\"]],[23,0,[\"triggerTargetElement\"]],[23,0,[\"autoPlacement\"]],[23,0,[\"viewportElement\"]],[23,0,[\"viewportPadding\"]],[23,0,[\"overlayId\"]],[23,4,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"        \"],[14,3,[[28,\"hash\",null,[[\"close\"],[[23,0,[\"close\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,2,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip.hbs"
    }
  });
  _exports.default = _default;
});