define("ember-bootstrap/components/base/bs-modal/header", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-modal/header", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _header, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  /**
  
   Modal header element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalHeader
   @namespace Components
   @extends Ember.Component
   @public
   */
  let ModalHeader = (_dec = (0, _component.layout)(_header.default), _dec2 = (0, _component.classNames)('modal-header'), _dec(_class = _dec2(_class = (_class2 = class ModalHeader extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * Show a close button (x icon)
       *
       * @property closeButton
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "closeButton", _descriptor, this);
      /**
       * The title to display in the modal header
       *
       * @property title
       * @type string
       * @default null
       * @public
       */
      /**
       * @property titleComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "titleComponent", _descriptor2, this);
      /**
       * @property closeComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "closeComponent", _descriptor3, this);
    }
    /**
     * @event onClose
     * @public
     */
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "closeButton", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "titleComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-modal/header/title';
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "closeComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-modal/header/close';
    }
  })), _class2)) || _class) || _class);
  _exports.default = ModalHeader;
});