define("ember-bootstrap/components/base/bs-form/element/layout/horizontal", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/layout", "ember-bootstrap/templates/components/bs-form/element/layout/horizontal", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _layout, _horizontal, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  /**
  
   @class FormElementLayoutHorizontal
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  let FormElementLayoutHorizontal = (_dec = (0, _component.layout)(_horizontal.default), _dec2 = Ember.computed('horizontalLabelGridClass').readOnly(), _dec(_class = (_class2 = class FormElementLayoutHorizontal extends _layout.default {
    constructor() {
      super(...arguments);
      /**
       * The Bootstrap grid class for form labels within a horizontal layout form.
       *
       * @property horizontalLabelGridClass
       * @type string
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "horizontalLabelGridClass", _descriptor, this);
    }
    /**
     * Computed property that specifies the Bootstrap grid class for form controls within a horizontal layout form.
     *
     * @property horizontalInputGridClass
     * @type string
     * @readonly
     * @private
     */
    get horizontalInputGridClass() {
      if (Ember.isBlank(this.get('horizontalLabelGridClass'))) {
        return undefined;
      }
      let parts = this.get('horizontalLabelGridClass').split('-');
      (true && !(parts.length === 3) && Ember.assert('horizontalInputGridClass must match format bootstrap grid column class', parts.length === 3));
      parts[2] = 12 - parts[2];
      return parts.join('-');
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "horizontalLabelGridClass", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "horizontalInputGridClass", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "horizontalInputGridClass"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormElementLayoutHorizontal;
});