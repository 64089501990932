define("ember-bootstrap/components/base/bs-modal/footer", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-modal/footer", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _footer, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  /**
  
   Modal footer element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalFooter
   @namespace Components
   @extends Ember.Component
   @public
   */
  let ModalFooter = (_dec = (0, _component.layout)(_footer.default), _dec2 = (0, _component.tagName)('form'), _dec3 = (0, _component.classNames)('modal-footer'), _dec4 = Ember.computed.notEmpty('submitTitle'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class ModalFooter extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * The title of the default close button. Will be ignored (i.e. no close button) if you provide your own block
       * template.
       *
       * @property closeTitle
       * @type string
       * @default 'Ok'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "closeTitle", _descriptor, this);
      /**
       * The title of the submit button (primary button). Will be ignored (i.e. no button) if set to null or if you provide
       * your own block template.
       *
       * @property submitTitle
       * @type string
       * @default null
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "hasSubmitButton", _descriptor2, this);
      /**
       * Set to true to disable the submit button. If you bind this to some property that indicates if submitting is allowed
       * (form validation for example) this can be used to prevent the user from pressing the submit button.
       *
       * @property submitDisabled
       * @type boolean
       * @default false
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "submitDisabled", _descriptor3, this);
      /**
       * The type of the submit button (primary button).
       *
       * @property submitButtonType
       * @type string
       * @default 'primary'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "submitButtonType", _descriptor4, this);
      /**
       * @property buttonComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "buttonComponent", _descriptor5, this);
    }
    /**
     * The action to send to the parent modal component when the modal footer's form is submitted
     *
     * @event onSubmit
     * @public
     */
    /**
     * @event onClose
     * @public
     */
    submit(e) {
      e.preventDefault();
      // send to parent bs-modal component
      this.get('onSubmit')();
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "closeTitle", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Ok';
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasSubmitButton", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "submitDisabled", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "submitButtonType", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'primary';
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "buttonComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-button';
    }
  })), _class2)) || _class) || _class) || _class);
  _exports.default = ModalFooter;
});