define("ember-bootstrap/components/base/bs-accordion/item/title", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-accordion/title", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _title, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  /**
   Component for an accordion item title.
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionItemTitle
   @namespace Components
   @extends Ember.Component
   @public
   */
  let AccordionItemTitle = (_dec = (0, _component.layout)(_title.default), _dec2 = (0, _component.classNameBindings)('collapsed:collapsed:expanded'), _dec(_class = _dec2(_class = (_class2 = class AccordionItemTitle extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ariaRole", _descriptor, this);
    }
    /**
     * @property collapsed
     * @type boolean
     * @public
     */

    /**
     * @property disabled
     * @type boolean
     * @private
     */
    /**
     * @event onClick
     * @public
     */
    onClick() {}
    click(e) {
      e.preventDefault();
      if (!this.get('disabled')) {
        this.get('onClick')();
      }
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "ariaRole", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'tab';
    }
  })), _class2)) || _class) || _class);
  _exports.default = AccordionItemTitle;
});