define("ember-bootstrap/components/base/bs-button-group/button", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-bootstrap/components/bs-button", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _bsButton, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  /**
   Internal component for button-group buttons
  
   @class ButtonGroupButton
   @namespace Components
   @extends Components.Button
   @private
   */
  let ButtonGroupButton = (_dec = Ember.computed('buttonGroupType', 'groupValue.[]', 'value').readOnly(), (_class = class ButtonGroupButton extends _bsButton.default {
    constructor() {
      super(...arguments);
      /**
       * @property groupValue
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "groupValue", _descriptor, this);
      /**
       * @property buttonGroupType
       * @type string
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "buttonGroupType", _descriptor2, this);
    }
    /**
     * @property active
     * @type boolean
     * @readonly
     * @private
     */
    get active() {
      let {
        value,
        groupValue
      } = this.getProperties('value', 'groupValue');
      if (this.get('buttonGroupType') === 'radio') {
        return value === groupValue;
      } else {
        if (Ember.isArray(groupValue)) {
          return groupValue.indexOf(value) !== -1;
        }
      }
      return false;
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "groupValue", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "buttonGroupType", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "active", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "active"), _class.prototype)), _class));
  _exports.default = ButtonGroupButton;
});