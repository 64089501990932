define("ember-bootstrap/components/bs-form/element/control/textarea", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/control/textarea", "ember-bootstrap/utils/cp/form-validation-class"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _textarea, _formValidationClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  let FormElementControlTextarea = (_dec = (0, _component.classNameBindings)('formValidationClass'), _dec2 = (0, _formValidationClass.default)('validationType'), _dec(_class = (_class2 = class FormElementControlTextarea extends _textarea.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "formValidationClass", _descriptor, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "formValidationClass", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = FormElementControlTextarea;
});