define("ember-bootstrap/utils/dom", ["exports", "require"], function (_exports, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findElementById = findElementById;
  _exports.getDOM = getDOM;
  _exports.getDestinationElement = getDestinationElement;
  function childNodesOfElement(element) {
    let children = [];
    let child = element.firstChild;
    while (child) {
      children.push(child);
      child = child.nextSibling;
    }
    return children;
  }
  function findElementById(doc, id) {
    if (doc.getElementById) {
      return doc.getElementById(id);
    }
    let nodes = childNodesOfElement(doc);
    let node;
    while (nodes.length) {
      node = nodes.shift();
      if (node.getAttribute && node.getAttribute('id') === id) {
        return node;
      }
      nodes = childNodesOfElement(node).concat(nodes);
    }
  }

  // Private Ember API usage. Get the dom implementation used by the current
  // renderer, be it native browser DOM or Fastboot SimpleDOM
  function getDOM(context) {
    let {
      renderer
    } = context;
    if (!renderer._dom) {
      // pre glimmer2
      let container = Ember.getOwner ? Ember.getOwner(context) : context.container;
      let documentService = container.lookup('service:-document');
      if (documentService) {
        return documentService;
      }
      renderer = container.lookup('renderer:-dom');
    }
    if (renderer._dom && renderer._dom.document) {
      return renderer._dom.document;
    } else {
      throw new Error('Could not get DOM');
    }
  }
  function getDestinationElement(context) {
    let dom = getDOM(context);
    let destinationElement = findElementById(dom, 'ember-bootstrap-wormhole');
    if (true /* DEBUG */ && !destinationElement) {
      let config = Ember.getOwner(context).resolveRegistration('config:environment');
      if (config.environment === 'test' && typeof FastBoot === 'undefined') {
        let id;
        if (_require.default.has('@ember/test-helpers/dom/get-root-element')) {
          try {
            id = (0, _require.default)('@ember/test-helpers/dom/get-root-element').default().id;
          } catch (ex) {
            // no op
          }
        }
        if (!id) {
          return document.querySelector('#ember-testing > .ember-view');
        }
        return document.getElementById(id);
      }
      (true && Ember.warn(`No wormhole destination element found for component ${context}. If you have set \`insertEmberWormholeElementToDom\` to false, you should insert a \`div#ember-bootstrap-wormhole\` manually!`, false, {
        id: 'ember-bootstrap.no-destination-element'
      }));
    }
    return destinationElement;
  }
});