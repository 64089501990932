define("ember-bootstrap/templates/components/bs-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OQs5pkHg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[23,0,[\"hidden\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"dismissible\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"aria-label\",\"Close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,0,[\"dismiss\"]]]],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-alert.hbs"
    }
  });
  _exports.default = _default;
});