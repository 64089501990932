define("ember-bootstrap/components/bs-form/element/layout/horizontal/checkbox", ["exports", "@ember-decorators/component", "ember-bootstrap/components/bs-form/element/layout/horizontal", "ember-bootstrap/templates/components/bs-form/element/layout/horizontal/checkbox"], function (_exports, _component, _horizontal, _checkbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  /**
  
   @class FormElementLayoutHorizontalCheckbox
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  let FormElementLayoutHorizontal = (_dec = (0, _component.layout)(_checkbox.default), _dec(_class = class FormElementLayoutHorizontal extends _horizontal.default {}) || _class);
  _exports.default = FormElementLayoutHorizontal;
});