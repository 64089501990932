define("ember-bootstrap/components/bs-nav/link-to", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-nav/link-to"], function (_exports, _component, _linkTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let NavLinkTo = (_dec = (0, _component.classNames)('nav-link'), _dec(_class = class NavLinkTo extends _linkTo.default {}) || _class);
  _exports.default = NavLinkTo;
});