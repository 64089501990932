define("ember-bootstrap/components/base/bs-form/element/control/textarea", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/control", "ember-bootstrap/components/base/bs-form/element/control/input"], function (_exports, _component, _control, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  /**
  
   @class FormElementControlTextarea
   @namespace Components
   @extends Components.FormElementControl
   @private
   */
  let FormElementControlTextarea = (_dec = (0, _component.attributeBindings)(..._input.baseAttributes, 'value', 'placeholder', 'minlength', 'maxlength', 'autocomplete', 'autocapitalize', 'autocorrect', 'spellcheck', 'rows', 'cols', 'wrap'), _dec2 = (0, _component.tagName)('textarea'), _dec3 = (0, _component.classNames)('form-control'), _dec(_class = _dec2(_class = _dec3(_class = class FormElementControlTextarea extends _control.default {
    change(event) {
      this.get('onChange')(event.target.value);
    }
    input(event) {
      this.get('onChange')(event.target.value);
    }
  }) || _class) || _class) || _class);
  _exports.default = FormElementControlTextarea;
});