define("ember-leaflet/helpers/ember-leaflet-assign-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberLeafletAssignTo = emberLeafletAssignTo;
  function emberLeafletAssignTo(_ref, _ref2) {
    let [object] = _ref;
    let {
      key,
      value,
      onChange
    } = _ref2;
    object[key] = value;

    // we need to send out the assigned object because ember freezes helper arguments
    onChange(object);
  }
  var _default = Ember.Helper.helper(emberLeafletAssignTo);
  _exports.default = _default;
});