define("ember-google-maps/components/g-map/directions", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/directions", "ember-concurrency"], function (_exports, _mapComponent, _directions, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A wrapper for the google.maps.directionsService API.
   *
   * @class Directions
   * @namespace GMap
   * @module ember-google-maps/components/g-map/directions
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    googleMapsApi: Ember.inject.service(),
    layout: _directions.default,
    _type: 'directions',
    _pluralType: 'directions',
    _ignoredAttrs: ['onDirectionsChanged'],
    _requiredOptions: ['origin', 'destination', 'travelMode', 'waypoints'],
    _watchedOptions: ['waypoints.[]'],
    directionsService: Ember.computed.reads('googleMapsApi.directionsService'),
    init() {
      this._super(...arguments);
      this.waypoints = Ember.A();
      this.publicAPI.reopen({
        directions: 'directions',
        waypoints: 'waypoints',
        actions: {
          route: 'route'
        }
      });
    },
    _addComponent() {
      return this.route();
    },
    _updateComponent() {
      return this.route();
    },
    /**
     * Fetch routing information from DirectionsService.
     *
     * This should be run after rendering to avoid triggering the request several
     * times on initial render if there are several waypoints.
     *
     * @method route
     * @public
     */
    route() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        Ember.run.scheduleOnce('afterRender', () => {
          Ember.get(this, '_route').perform().then(result => resolve(result)).catch(e => {
            if (!(0, _emberConcurrency.didCancel)(e)) {
              reject(e);
            }
          });
        });
      });
    },
    _route: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(300);
      let options = Ember.get(this, '_options');
      delete options.map;
      let directions = yield Ember.get(this, 'fetchDirections').perform(options);
      Ember.setProperties(this, {
        directions,
        mapComponent: directions
      });
      Ember.run.schedule('afterRender', () => Ember.tryInvoke(this, 'onDirectionsChanged', [this.publicAPI]));
      return directions;
    }).restartable(),
    fetchDirections: (0, _emberConcurrency.task)(function* (options) {
      let directionsService = yield Ember.get(this, 'directionsService');
      let request = new Ember.RSVP.Promise((resolve, reject) => {
        directionsService.route(options, (response, status) => {
          if (status === 'OK') {
            resolve(response);
          } else {
            reject(status);
          }
        });
      });
      let directions = yield request;
      return directions;
    }),
    _registerWaypoint(waypoint) {
      Ember.get(this, 'waypoints').pushObject(waypoint);
    },
    _unregisterWaypoint(waypoint) {
      Ember.get(this, 'waypoints').removeObject(waypoint);
    }
  });
  _exports.default = _default;
});