define("ember-bootstrap/components/base/bs-form/element/errors", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-form/element/errors", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _errors, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;
  /**
   @class FormElementErrors
   @namespace Components
   @extends Ember.Component
   @private
   */
  let FormElementErrors = (_dec = (0, _component.layout)(_errors.default), _dec2 = (0, _component.tagName)(''), _dec(_class = _dec2(_class = (_class2 = class FormElementErrors extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * @property show
       * @type {Boolean}
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "show", _descriptor, this);
      /**
       * @property messages
       * @type {Ember.Array}
       * @public
       */
      /**
       * Whether or not should display several errors at the same time.
       *
       * @default false
       * @property showMultipleErrors
       * @public
       * @type {Boolean}
       */
      (0, _initializerDefineProperty2.default)(this, "showMultipleErrors", _descriptor2, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "show", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showMultipleErrors", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class2)) || _class) || _class);
  _exports.default = FormElementErrors;
});