define("ember-google-maps/components/g-map/overlay", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/overlay", "ember-google-maps/utils/helpers"], function (_exports, _mapComponent, _overlay, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A wrapper for the google.maps.Overlay class.
   *
   * @class Overlay
   * @namespace GMap
   * @module ember-google-maps/components/g-map/overlay
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    layout: _overlay.default,
    _type: 'overlay',
    position: _helpers.position,
    paneName: 'overlayMouseTarget',
    _targetPane: null,
    _eventTarget: Ember.computed.reads('content'),
    innerContainerStyle: Ember.String.htmlSafe('transform: translateX(-50%) translateY(-100%);'),
    _contentId: Ember.computed(function () {
      return `ember-google-maps-overlay-${Ember.guidFor(this)}`;
    }),
    _addComponent() {
      let Overlay = new google.maps.OverlayView();
      return new Ember.RSVP.Promise(resolve => {
        Overlay.onAdd = () => Ember.run.join(this, 'add');
        Overlay.draw = () => Ember.run.schedule('render', () => {
          if (this.isDestroyed) {
            return;
          }
          this._initialDraw();
          resolve();
        });
        Overlay.onRemove = () => Ember.run.join(this, 'destroy');
        Ember.set(this, 'mapComponent', Overlay);
        Ember.run.next(() => {
          this.mapComponent.setMap(Ember.get(this, 'map'));
        });
      });
    },
    _initialDraw() {
      this.fetchOverlayContent();
      this.mapComponent.draw = () => this.draw();
      this._updateComponent();
    },
    _updateComponent() {
      this.mapComponent.draw();
    },
    add() {
      if (this.isDestroyed) {
        return;
      }
      let panes = this.mapComponent.getPanes();
      Ember.set(this, '_targetPane', Ember.get(panes, this.paneName));
    },
    draw() {
      let overlayProjection = this.mapComponent.getProjection();
      let position = Ember.get(this, 'position');
      let point = overlayProjection.fromLatLngToDivPixel(position);
      this.content.style.cssText = `
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      transform: translateX(${point.x}px) translateY(${point.y}px);
    `;
    },
    fetchOverlayContent() {
      let element = document.getElementById(Ember.get(this, '_contentId'));
      Ember.set(this, 'content', element);
    },
    getPosition() {
      return Ember.get(this, 'position');
    }
  });
  _exports.default = _default;
});