define("ember-bootstrap/templates/components/bs-form/element/layout/vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YFRCEl7t",
    "block": "{\"symbols\":[\"@labelComponent\",\"&default\",\"@errorsComponent\",\"@helpTextComponent\"],\"statements\":[[4,\"if\",[[24,[\"hasLabel\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[23,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[14,2],[0,\"\\n\"],[1,[28,\"component\",[[23,3,[]]],null],false],[0,\"\\n\"],[1,[28,\"component\",[[23,4,[]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical.hbs"
    }
  });
  _exports.default = _default;
});