define("ember-bootstrap/components/base/bs-progress", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-progress", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _bsProgress, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  /**
    Component to display a Bootstrap progress bar, see http://getbootstrap.com/components/#progress.
  
    ### Usage
  
    The component yields a [Components.ProgressBar)(Components.ProgressBar.html) component that represents a single bar.
    Use the `value` property to control the progress bar's width. To apply the different styling options supplied by
    Bootstrap, use the appropriate properties like `type`, `showLabel`, `striped` or `animate`.
  
    ```hbs
    <BsProgress as |pg| >
      <pg.bar @value={{this.progressValue}} @minValue={{0}} @maxValue={{10}} @showLabel={{true}} @type="danger" />
    </BsProgress>
    ```
  
    ### Stacked
  
    You can place multiple progress bar components in a single progress component to
    create a stack of progress bars as seen in http://getbootstrap.com/components/#progress-stacked.
  
    ```hbs
    <BsProgress as |pg| >
      <pg.bar @value={{this.progressValue1}} @type="success" />
      <pg.bar @value={{this.progressValue2}} @type="warning" />
      <pg.bar @value={{this.progressValue3}} @type="danger" />
    </BsProgress>
    ```
  
    @class Progress
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Progress = (_dec = (0, _component.layout)(_bsProgress.default), _dec2 = (0, _component.classNames)('progress'), _dec(_class = _dec2(_class = (_class2 = class Progress extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * @property progressBarComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "progressBarComponent", _descriptor, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "progressBarComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-progress/bar';
    }
  })), _class2)) || _class) || _class);
  _exports.default = Progress;
});