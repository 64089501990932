define("ember-bootstrap/components/bs-form/element", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-bootstrap/components/base/bs-form/element", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _element, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  let FormElement = (_class = class FormElement extends _element.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "doNotShowValidationForEventTargets", _descriptor, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "doNotShowValidationForEventTargets", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['.input-group-append', '.input-group-prepend'];
    }
  })), _class);
  _exports.default = FormElement;
});