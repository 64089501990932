define("ember-bootstrap/templates/components/bs-button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jWWLEu6T",
    "block": "{\"symbols\":[\"@value\",\"@type\",\"&default\"],\"statements\":[[14,3,[[28,\"hash\",null,[[\"button\"],[[28,\"component\",[[23,0,[\"buttonComponent\"]]],[[\"buttonGroupType\",\"groupValue\",\"onClick\"],[[23,2,[]],[23,1,[]],[23,0,[\"buttonPressed\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button-group.hbs"
    }
  });
  _exports.default = _default;
});