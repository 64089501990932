define("ember-bootstrap/templates/components/bs-form/element/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zkI64pX9",
    "block": "{\"symbols\":[\"message\",\"@messages\"],\"statements\":[[4,\"if\",[[23,0,[\"show\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"showMultipleErrors\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"pre-scrollable\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[22,\"feedbackClass\"]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[23,0,[\"feedbackClass\"]]],[8],[1,[23,2,[\"firstObject\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/errors.hbs"
    }
  });
  _exports.default = _default;
});