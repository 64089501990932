define("ember-bootstrap/components/bs-form/group", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/group"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _group) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor;
  let FormGroup = (_dec = (0, _component.classNames)('form-group'), _dec2 = (0, _component.classNameBindings)('isHorizontal:row'), _dec3 = Ember.computed.equal('formLayout', 'horizontal').readOnly(), _dec(_class = _dec2(_class = (_class2 = class FormGroup extends _group.default {
    constructor() {
      super(...arguments);
      /**
       * Indicates whether the form type equals `horizontal`
       *
       * @property isHorizontal
       * @type boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "isHorizontal", _descriptor, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isHorizontal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = FormGroup;
});