define("ember-bootstrap/components/base/bs-tab/pane", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/templates/components/bs-tab/pane", "ember-bootstrap/mixins/component-child", "ember-bootstrap/utils/transition-end", "ember-bootstrap/utils/cp/uses-transition", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _object, _pane, _componentChild, _transitionEnd, _usesTransition, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  /**
   The tab pane of a tab component.
   See [Components.Tab](Components.Tab.html) for examples.
  
   @class TabPane
   @namespace Components
   @extends Ember.Component
   @uses Mixins.ComponentChild
   @public
   */
  let TabPane = (_dec = (0, _component.layout)(_pane.default), _dec2 = (0, _component.classNameBindings)('active', 'usesTransition:fade'), _dec3 = (0, _component.classNames)('tab-pane'), _dec4 = Ember.computed('activeId', 'elementId').readOnly(), _dec5 = (0, _usesTransition.default)('fade'), _dec6 = (0, _object.observes)('isActive'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class TabPane extends Ember.Component.extend(_componentChild.default) {
    constructor() {
      super(...arguments);
      this.ariaRole = 'tabpanel';
      /**
       * @property activeId
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "activeId", _descriptor, this);
      /**
       * Used to apply Bootstrap's "active" class
       *
       * @property active
       * @type boolean
       * @default false
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "active", _descriptor2, this);
      /**
       * Used to trigger the Bootstrap visibility classes.
       *
       * @property showContent
       * @type boolean
       * @default false
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "showContent", _descriptor3, this);
      /**
       * The title for this tab pane. This is used by the `bs-tab` component to automatically generate
       * the tab navigation.
       * See the [Components.Tab](Components.Tab.html) for examples.
       *
       * @property title
       * @type string
       * @default null
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "title", _descriptor4, this);
      /**
       * An optional group title used by the `bs-tab` component to group all panes with the same group title
       * under a common drop down in the tab navigation.
       * See the [Components.Tab](Components.Tab.html) for examples.
       *
       * @property groupTitle
       * @type string
       * @default null
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "groupTitle", _descriptor5, this);
      /**
       * Use fade animation when switching tabs.
       *
       * @property fade
       * @type boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "fade", _descriptor6, this);
      /**
       * The duration of the fade out animation
       *
       * @property fadeDuration
       * @type integer
       * @default 150
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "fadeDuration", _descriptor7, this);
      /**
       * Use CSS transitions?
       *
       * @property usesTransition
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "usesTransition", _descriptor8, this);
    }
    /**
     * True if this pane is active (visible)
     *
     * @property isActive
     * @type boolean
     * @readonly
     * @private
     */
    get isActive() {
      return this.get('activeId') === this.get('elementId');
    }
    /**
     * Show the pane
     *
     * @method show
     * @protected
     */
    show() {
      if (this.get('usesTransition')) {
        (0, _transitionEnd.default)(this.get('element'), this.get('fadeDuration')).then(() => {
          if (!this.get('isDestroyed')) {
            this.setProperties({
              active: true,
              showContent: true
            });
          }
        });
      } else {
        this.set('active', true);
      }
    }

    /**
     * Hide the pane
     *
     * @method hide
     * @protected
     */
    hide() {
      if (this.get('usesTransition')) {
        (0, _transitionEnd.default)(this.get('element'), this.get('fadeDuration')).then(() => {
          if (!this.get('isDestroyed')) {
            this.set('active', false);
          }
        });
        this.set('showContent', false);
      } else {
        this.set('active', false);
      }
    }
    _showHide() {
      if (this.get('isActive')) {
        this.show();
      } else {
        this.hide();
      }
    }
    init() {
      super.init(...arguments);
      Ember.run.scheduleOnce('afterRender', this, function () {
        // isActive comes from parent component, so only available after render...
        this.set('active', this.get('isActive'));
        this.set('showContent', this.get('isActive') && this.get('fade'));
      });
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "activeId", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isActive", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isActive"), _class2.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "active", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showContent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "title", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "groupTitle", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fadeDuration", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 150;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "usesTransition", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "_showHide", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "_showHide"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = TabPane;
});