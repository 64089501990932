define("ember-bootstrap/components/base/bs-carousel/slide", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/mixins/component-child", "ember-bootstrap/templates/components/bs-carousel/slide", "ember-bootstrap/utils/cp/overrideable"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _object, _componentChild, _slide, _overrideable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor;
  /**
    A visible user-defined slide.
  
    See [Components.Carousel](Components.Carousel.html) for examples.
  
    @class CarouselSlide
    @namespace Components
    @extends Ember.Component
    @public
   */
  let CarouselSlide = (_dec = (0, _component.classNameBindings)('active'), _dec2 = (0, _component.layout)(_slide.default), _dec3 = (0, _overrideable.default)('isCurrentSlide', 'presentationState', function () {
    return this.get('isCurrentSlide') && this.get('presentationState') === null;
  }), _dec4 = Ember.computed('currentSlide').readOnly(), _dec5 = Ember.computed('followingSlide').readOnly(), _dec6 = (0, _object.observes)('presentationState'), _dec(_class = _dec2(_class = (_class2 = class CarouselSlide extends Ember.Component.extend(_componentChild.default) {
    constructor() {
      super(...arguments);
      /**
       * Defines slide visibility.
       *
       * @property active
       * @type boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "active", _descriptor, this);
      /**
       * Slide is moving to the left.
       *
       * @property left
       * @type boolean
       * @private
       */
      this.left = false;
      /**
       * Next to appear in a left sliding.
       *
       * @property next
       * @type boolean
       * @private
       */
      this.next = false;
      /**
       * Next to appear in a right sliding.
       *
       * @property prev
       * @type boolean
       * @private
       */
      this.prev = false;
      /**
       * Slide is moving to the right.
       *
       * @property right
       * @type boolean
       * @private
       */
      this.right = false;
    }
    /**
     * @private
     * @property isCurrentSlide
     * @type boolean
     */
    get isCurrentSlide() {
      return this.get('currentSlide') === this;
    }

    /**
     * @private
     * @property isFollowingSlide
     * @type boolean
     */
    get isFollowingSlide() {
      return this.get('followingSlide') === this;
    }
    /**
     * Coordinates the execution of a presentation.
     *
     * @method presentationStateObserver
     * @private
     */
    presentationStateObserver() {
      let presentationState = this.get('presentationState');
      if (this.get('isCurrentSlide')) {
        switch (presentationState) {
          case 'didTransition':
            this.currentSlideDidTransition();
            break;
          case 'willTransit':
            this.currentSlideWillTransit();
            break;
        }
      }
      if (this.get('isFollowingSlide')) {
        switch (presentationState) {
          case 'didTransition':
            this.followingSlideDidTransition();
            break;
          case 'willTransit':
            this.followingSlideWillTransit();
            break;
        }
      }
    }

    /**
     * @method currentSlideDidTransition
     * @private
     */
    currentSlideDidTransition() {
      this.set(this.get('directionalClassName'), false);
      this.set('active', false);
    }

    /**
     * @method currentSlideWillTransit
     * @private
     */
    currentSlideWillTransit() {
      this.set('active', true);
      Ember.run.next(this, function () {
        this.set(this.get('directionalClassName'), true);
      });
    }

    /**
     * @method followingSlideDidTransition
     * @private
     */
    followingSlideDidTransition() {
      this.set('active', true);
      this.set(this.get('directionalClassName'), false);
      this.set(this.get('orderClassName'), false);
    }

    /**
     * @method followingSlideWillTransit
     * @private
     */
    followingSlideWillTransit() {
      this.set(this.get('orderClassName'), true);
      Ember.run.next(this, function () {
        this.reflow();
        this.set(this.get('directionalClassName'), true);
      });
    }

    /**
     * Makes things more stable, especially when fast changing.
     */
    reflow() {
      this.element.offsetHeight;
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "active", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isCurrentSlide", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isCurrentSlide"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isFollowingSlide", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isFollowingSlide"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "presentationStateObserver", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "presentationStateObserver"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = CarouselSlide;
});