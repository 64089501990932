define("ember-bootstrap/components/bs-dropdown/menu/link-to", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-dropdown/menu/link-to"], function (_exports, _component, _linkTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let DropdownMenuLinkTo = (_dec = (0, _component.classNames)('dropdown-item'), _dec(_class = class DropdownMenuLinkTo extends _linkTo.default {}) || _class);
  _exports.default = DropdownMenuLinkTo;
});