define("ember-bootstrap/components/base/bs-tooltip/element", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-tooltip/element", "ember-bootstrap/components/base/bs-contextual-help/element"], function (_exports, _component, _element, _element2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  /**
   Internal component for tooltip's markup. Should not be used directly.
  
   @class TooltipElement
   @namespace Components
   @extends Components.ContextualHelpElement
   @private
   */
  let TooltipElement = (_dec = (0, _component.layout)(_element.default), _dec(_class = class TooltipElement extends _element2.default {}) || _class);
  _exports.default = TooltipElement;
});