define("ember-google-maps/components/g-map", ["exports", "ember-google-maps/mixins/process-options", "ember-google-maps/mixins/register-events", "ember-google-maps/utils/public-api", "ember-google-maps/templates/components/g-map", "ember-google-maps/utils/helpers", "ember-concurrency"], function (_exports, _processOptions, _registerEvents, _publicApi, _gMap, _helpers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GMapAPI = {
    id: 'mapId',
    map: 'map',
    components: 'components',
    actions: {
      update: '_updateMap',
      trigger: '_trigger'
    }
  };

  /**
   * @class GMap
   * @module ember-google-maps/components/g-map
   * @extends Ember.Component
   * @uses ProcessOptions
   * @uses RegisterEvents
   */
  var _default = Ember.Component.extend(_processOptions.default, _registerEvents.default, {
    /**
     * @property googleMapsApi
     * @type GoogleMapsApi
     * @readOnly
     */
    googleMapsApi: Ember.inject.service(),
    fastboot: Ember.computed(function () {
      let owner = Ember.getOwner(this);
      return owner.lookup('service:fastboot');
    }),
    layout: _gMap.default,
    tagName: '',
    _requiredOptions: ['center', 'zoom'],
    /**
     * Zoom level for the map
     *
     * @property zoom
     * @type {Number}
     * @default 8
     * @public
     */
    zoom: 8,
    /**
     * The latitude and longitude of the center of the map.
     *
     * @property center
     * @type {google.maps.LatLng}
     * @public
     */
    center: _helpers.position,
    google: Ember.computed.reads('googleMapsApi.google'),
    mapComponent: Ember.computed.reads('map'),
    /**
     * A unique id for the current map instance.
     *
     * @property mapId
     * @type {String}
     * @public
     */
    mapId: Ember.computed(function () {
      return `ember-google-map-${Ember.guidFor(this)}`;
    }),
    /**
     * We detect whether there is a custom canvas on initial render.
     */
    _isInitialRender: true,
    _customCanvas: null,
    _needsCanvas: Ember.computed.not('_customCanvas'),
    init() {
      this._super(...arguments);
      this.components = {};
      this.gMap = {};
      this.publicAPI = new _publicApi.default(this, GMapAPI);
      this._internalAPI = {
        _registerCanvas: this._registerCanvas.bind(this),
        _registerComponent: this._registerComponent.bind(this),
        _unregisterComponent: this._unregisterComponent.bind(this)
      };
      this._canvasIsRendering = Ember.RSVP.defer();
      Ember.get(this, '_initMap').perform();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      if (Ember.get(this, 'map')) {
        this._updateMap();
      }
    },
    /**
     * Initialize the map, register events and prep internal components.
     *
     * @method _initMap
     * @private
     * @return
     */
    _initMap: (0, _emberConcurrency.task)(function* () {
      yield Ember.get(this, 'google');
      let canvas = yield this._canvasIsRendering.promise;
      let options = Ember.get(this, '_options');
      let map = new google.maps.Map(canvas, options);
      google.maps.event.addListenerOnce(map, 'idle', () => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        Ember.set(this, 'map', map);
        this.registerEvents();
        Ember.tryInvoke(this, 'onLoad', [this.publicAPI]);
        Ember.run.scheduleOnce('afterRender', this, () => {
          if (this.isDestroying || this.isDestroyed) {
            return;
          }
          this._waitForComponents().then(() => {
            this._componentsInitialized = true;
            Ember.tryInvoke(this, 'onComponentsLoad', [this.publicAPI]);
          });
        });
      });
    }),
    _waitForComponents() {
      let componentsAreInitialized = Object.keys(this.components).map(name => this.components[name]).reduce((array, componentGroup) => array.concat(componentGroup), []).map(components => Ember.get(components, 'isInitialized.promise'));
      return Ember.RSVP.all(componentsAreInitialized);
    },
    /**
     * Update the map options.
     *
     * @method _updateMap
     * @return
     */
    _updateMap() {
      let options = Ember.get(this, '_options');
      Ember.get(this, 'map').setOptions(options);
    },
    /**
     * Helper method to trigger Google Maps events.
     *
     * @method _trigger
     * @param {String} event Event name
     * @return
     */
    _trigger() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      google.maps.event.trigger(Ember.get(this, 'map'), ...args);
    },
    _registerCanvas(canvas) {
      Ember.set(this, 'canvas', canvas);
      this._notifyCanvasHasRendered();
    },
    _notifyCanvasHasRendered() {
      this._canvasIsRendering.resolve(this.canvas);
    },
    _endInitialRender() {
      if (Ember.get(this, 'fastboot.isFastBoot')) {
        return;
      }
      Ember.run.scheduleOnce('afterRender', this, () => {
        if (this.canvas) {
          Ember.set(this, '_customCanvas', this.canvas);
        }
        Ember.set(this, '_isInitialRender', false);
      });
    },
    /**
     * Register a contextual component with the map component.
     *
     * @method _registerComponent
     * @param {String} type Plural name of the component
     * @param {Object} componentAPI
     * @return
     */
    _registerComponent(type, componentAPI) {
      this.components[type] = this.components[type] || Ember.A();
      this.components[type].pushObject(componentAPI);
    },
    /**
     * Unregister a contextual component with the map component.
     *
     * @method _unregisterComponent
     * @param {String} type Name of the component
     * @param {Object} componentAPI
     * @return
     */
    _unregisterComponent(type, componentAPI) {
      this.components[type].removeObject(componentAPI);
    },
    _updateGMap() {
      for (var _len2 = arguments.length, props = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        props[_key2] = arguments[_key2];
      }
      Ember.setProperties(this.gMap, Object.assign({}, ...props));
    }
  });
  _exports.default = _default;
});