define("ember-bootstrap/components/base/bs-navbar/nav", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/components/bs-nav", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _bsNav, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2, _descriptor;
  /**
   * Component for the `.nav` element within a [Components.Navbar](Components.Navbar.html)
   * component. Have a look there for examples.
   *
   * Per [the bootstrap docs](http://getbootstrap.com/components/#navbar),
   * justified navbar nav links are not supported.
   *
   * @class NavbarNav
   * @namespace Components
   * @extends Components.Nav
   * @public
   */
  let NavbarNav = (_dec = (0, _component.classNames)('navbar-nav'), _dec(_class = (_class2 = class NavbarNav extends _bsNav.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "justified", _descriptor, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "justified", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class2)) || _class);
  _exports.default = NavbarNav;
});