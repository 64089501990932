define("ember-google-maps/components/-private-api/addon-factory", ["exports", "ember-google-maps/templates/components/-private-api/addon-factory"], function (_exports, _addonFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _addonFactory.default,
    tagName: '',
    init() {
      this._super(...arguments);
      if (!this.gMap) {
        this.gMap = {};
      }
    }
  });
  _exports.default = _default;
});