define("ember-bootstrap/components/base/bs-modal/header/close", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-modal/header/close", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _close, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;
  /**
  
   @class ModalHeaderClose
   @namespace Components
   @extends Ember.Component
   @private
   */
  let ModalHeaderClose = (_dec = (0, _component.layout)(_close.default), _dec2 = (0, _component.tagName)('button'), _dec3 = (0, _component.classNames)('close'), _dec4 = (0, _component.attributeBindings)('type', 'aria-label'), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class ModalHeaderClose extends Ember.Component {
    constructor() {
      super(...arguments);
      this['aria-label'] = 'Close';
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor, this);
    }
    /**
     * @event onClick
     * @public
     */
    onClick() {}
    click() {
      this.get('onClick')();
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button';
    }
  })), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = ModalHeaderClose;
});