define("ember-google-maps/templates/components/g-map/overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4VC5cZmu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"_targetPane\"]]],null,{\"statements\":[[4,\"in-element\",[[24,[\"_targetPane\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"id\",[22,\"_contentId\"]],[8],[0,\"\\n\"],[4,\"_private-api/detect-render\",null,[[\"style\",\"didRender\"],[[24,[\"innerContainerStyle\"]],[28,\"action\",[[23,0,[]],[24,[\"_updateComponent\"]]],null]]],{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/overlay.hbs"
    }
  });
  _exports.default = _default;
});