define("ember-bootstrap/components/base/bs-form/element/control", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  /**
  
   @class FormElementControl
   @namespace Components
   @extends Ember.Component
   @private
   */
  let FormElementControl = (_class = class FormElementControl extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * @property value
       * @public
       */
      /**
       * @property ariaDescribedBy
       * @type {string}
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "ariaDescribedBy", _descriptor, this);
    }
    /**
     * This action is called whenever the `value` changes
     *
     * @event onChange
     * @param {*} value
     * @public
     */
    onChange() {}
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ariaDescribedBy", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
  _exports.default = FormElementControl;
});