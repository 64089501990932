define("ember-bootstrap/components/base/bs-form/element/layout/vertical/checkbox", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/layout/vertical", "ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox"], function (_exports, _component, _vertical, _checkbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  /**
  
   @class FormElementLayoutVerticalCheckbox
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  let FormElementLayoutVerticalCheckbox = (_dec = (0, _component.layout)(_checkbox.default), _dec(_class = class FormElementLayoutVerticalCheckbox extends _vertical.default {}) || _class);
  _exports.default = FormElementLayoutVerticalCheckbox;
});