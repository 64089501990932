define("ember-google-maps/templates/components/g-map/control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tgYbStp8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[24,[\"_elementDestination\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/control.hbs"
    }
  });
  _exports.default = _default;
});