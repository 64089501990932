define("ember-bootstrap/components/base/bs-navbar/link-to", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-bootstrap/components/bs-nav/link-to", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _linkTo, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  /**
   * Extended `{{link-to}}` component for use within Navbars.
   *
   * @class NavbarLinkTo
   * @namespace Components
   * @extends Components.NavLinkTo
   * @public
   */
  let NavbarLinkTo = (_class = class NavbarLinkTo extends _linkTo.default {
    constructor() {
      super(...arguments);
      /**
       * @property collapseNavbar
       * @type {Boolean}
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "collapseNavbar", _descriptor, this);
    }
    /**
     * @event onCollapse
     * @private
     */
    onCollapse() {}
    click() {
      if (this.get('collapseNavbar')) {
        this.onCollapse();
      }
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "collapseNavbar", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  })), _class);
  _exports.default = NavbarLinkTo;
});