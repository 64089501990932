define("ember-bootstrap/components/base/bs-collapse", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/utils/transition-end", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _object, _transitionEnd, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  /**
    An Ember component that mimics the behaviour of [Bootstrap's collapse.js plugin](http://getbootstrap.com/javascript/#collapse)
  
    ### Usage
  
    ```hbs
    <BsCollapse @collapsed={{this.collapsed}}>
      <div class="well">
        <h2>Collapse</h2>
        <p>This is collapsible content</p>
      </div>
    </BsCollapse>
    ```
  
    @class Collapse
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Collapse = (_dec = (0, _component.classNameBindings)('collapse', 'collapsing'), _dec2 = Ember.computed.not('transitioning'), _dec3 = Ember.computed.alias('transitioning'), _dec4 = Ember.computed.and('collapse', 'active'), _dec5 = (0, _object.observes)('collapsed'), _dec6 = (0, _object.observes)('collapsedSize'), _dec7 = (0, _object.observes)('expandedSize'), _dec(_class = (_class2 = class Collapse extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * Collapsed/expanded state
       *
       * @property collapsed
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "collapsed", _descriptor, this);
      /**
       * True if this item is expanded
       *
       * @property active
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "active", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "collapse", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "collapsing", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "showContent", _descriptor5, this);
      /**
       * true if the component is currently transitioning
       *
       * @property transitioning
       * @type boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "transitioning", _descriptor6, this);
      /**
       * The size of the element when collapsed. Defaults to 0.
       *
       * @property collapsedSize
       * @type number
       * @default 0
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "collapsedSize", _descriptor7, this);
      /**
       * The size of the element when expanded. When null the value is calculated automatically to fit the containing elements.
       *
       * @property expandedSize
       * @type number
       * @default null
       * @public
       */
      this.expandedSize = null;
      /**
       * Usually the size (height) of the element is only set while transitioning, and reseted afterwards. Set to true to always set a size.
       *
       * @property resetSizeWhenNotCollapsing
       * @type boolean
       * @default true
       * @private
       */
      this.resetSizeWhenNotCollapsing = true;
      /**
       * The direction (height/width) of the collapse animation.
       * When setting this to 'width' you should also define custom CSS transitions for the width property, as the Bootstrap
       * CSS does only support collapsible elements for the height direction.
       *
       * @property collapseDimension
       * @type string
       * @default 'height'
       * @public
       */
      this.collapseDimension = 'height';
      /**
       * The duration of the fade transition
       *
       * @property transitionDuration
       * @type number
       * @default 350
       * @public
       */
      this.transitionDuration = 350;
    }
    setCollapseSize(size) {
      let dimension = this.get('collapseDimension');
      (true && !(["width", "height"].indexOf(dimension) !== -1) && Ember.assert(`collapseDimension must be either "width" or "height". ${dimension} given.`, ["width", "height"].indexOf(dimension) !== -1));
      this.element.style.width = dimension === 'width' && size ? `${size}px` : '';
      this.element.style.height = dimension === 'height' && size ? `${size}px` : '';
    }

    /**
     * The action to be sent when the element is about to be hidden.
     *
     * @event onHide
     * @public
     */
    onHide() {}

    /**
     * The action to be sent after the element has been completely hidden (including the CSS transition).
     *
     * @event onHidden
     * @public
     */
    onHidden() {}

    /**
     * The action to be sent when the element is about to be shown.
     *
     * @event onShow
     * @public
     */
    onShow() {}

    /**
     * The action to be sent after the element has been completely shown (including the CSS transition).
     *
     * @event onShown
     * @public
     */
    onShown() {}

    /**
     * Triggers the show transition
     *
     * @method show
     * @protected
     */
    show() {
      this.get('onShow')();
      this.setProperties({
        transitioning: true,
        active: true
      });
      this.setCollapseSize(this.get('collapsedSize'));
      (0, _transitionEnd.default)(this.get('element'), this.get('transitionDuration')).then(() => {
        if (this.get('isDestroyed')) {
          return;
        }
        this.set('transitioning', false);
        if (this.get('resetSizeWhenNotCollapsing')) {
          this.setCollapseSize(null);
        }
        this.get('onShown')();
      });
      Ember.run.next(this, function () {
        if (!this.get('isDestroyed')) {
          this.setCollapseSize(this.getExpandedSize('show'));
        }
      });
    }

    /**
     * Get the size of the element when expanded
     *
     * @method getExpandedSize
     * @param action
     * @return {Number}
     * @private
     */
    getExpandedSize(action) {
      let expandedSize = this.get('expandedSize');
      if (Ember.isPresent(expandedSize)) {
        return expandedSize;
      }
      let collapseElement = this.get('element');
      let prefix = action === 'show' ? 'scroll' : 'offset';
      let measureProperty = Ember.String.camelize(`${prefix}-${this.get('collapseDimension')}`);
      return collapseElement[measureProperty];
    }

    /**
     * Triggers the hide transition
     *
     * @method hide
     * @protected
     */
    hide() {
      this.get('onHide')();
      this.setProperties({
        transitioning: true,
        active: false
      });
      this.setCollapseSize(this.getExpandedSize('hide'));
      (0, _transitionEnd.default)(this.get('element'), this.get('transitionDuration')).then(() => {
        if (this.get('isDestroyed')) {
          return;
        }
        this.set('transitioning', false);
        if (this.get('resetSizeWhenNotCollapsing')) {
          this.setCollapseSize(null);
        }
        this.get('onHidden')();
      });
      Ember.run.next(this, function () {
        if (!this.get('isDestroyed')) {
          this.setCollapseSize(this.get('collapsedSize'));
        }
      });
    }
    _onCollapsedChange() {
      let collapsed = this.get('collapsed');
      let active = this.get('active');
      if (collapsed !== active) {
        return;
      }
      if (collapsed === false) {
        this.show();
      } else {
        this.hide();
      }
    }
    _updateCollapsedSize() {
      if (!this.get('resetSizeWhenNotCollapsing') && this.get('collapsed') && !this.get('collapsing')) {
        this.setCollapseSize(this.get('collapsedSize'));
      }
    }
    _updateExpandedSize() {
      if (!this.get('resetSizeWhenNotCollapsing') && !this.get('collapsed') && !this.get('collapsing')) {
        this.setCollapseSize(this.get('expandedSize'));
      }
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "collapsed", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "active", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return !this.get('collapsed');
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "collapse", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "collapsing", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showContent", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "transitioning", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "collapsedSize", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "_onCollapsedChange", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_onCollapsedChange"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "_updateCollapsedSize", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "_updateCollapsedSize"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "_updateExpandedSize", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "_updateExpandedSize"), _class2.prototype)), _class2)) || _class);
  _exports.default = Collapse;
});