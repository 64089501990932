define("ember-bootstrap/components/base/bs-dropdown/menu/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Component for a dropdown menu item.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownMenuItem
   @namespace Components
   @extends Ember.Component
   @public
   */
  class DropdownMenuItem extends Ember.Component {}
  _exports.default = DropdownMenuItem;
});