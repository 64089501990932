define("ember-bootstrap/components/bs-form", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-bootstrap/components/base/bs-form"], function (_exports, _applyDecoratedDescriptor2, _bsForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let Form = (_dec = Ember.computed('formLayout').readOnly(), (_class = class Form extends _bsForm.default {
    get layoutClass() {
      let layout = this.get('formLayout');
      return layout === 'inline' ? 'form-inline' : null;
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "layoutClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "layoutClass"), _class.prototype)), _class));
  _exports.default = Form;
});