define("ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1TNsRu51",
    "block": "{\"symbols\":[\"&default\",\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-check\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n  \"],[1,[28,\"component\",[[23,2,[]]],null],false],[0,\"\\n  \"],[1,[28,\"component\",[[23,3,[]]],null],false],[0,\"\\n  \"],[1,[28,\"component\",[[23,4,[]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox.hbs"
    }
  });
  _exports.default = _default;
});