define("ember-bootstrap/components/base/bs-alert", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/templates/components/bs-alert", "ember-bootstrap/utils/cp/type-class", "ember-bootstrap/utils/cp/listen-to", "ember-bootstrap/utils/cp/uses-transition", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _object, _bsAlert, _typeClass, _listenTo, _usesTransition, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  /**
    Implements [Bootstrap alerts](http://getbootstrap.com/components/#alerts)
  
    ### Usage
  
    By default it is a user dismissible alert with a fade out animation, both of which can be disabled. Be sure to set the
    `type` property for proper styling.
  
    ```hbs
    <BsAlert @type="success">
      <strong>Well done!</strong> You successfully read this important alert message.
    </BsAlert>
    ```
  
    @class Alert
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Alert = (_dec = (0, _component.layout)(_bsAlert.default), _dec2 = (0, _component.classNameBindings)('alert', 'fade', 'dismissible:alert-dismissible', 'typeClass'), _dec3 = (0, _listenTo.default)('visible'), _dec4 = Ember.computed.not('_visible'), _dec5 = Ember.computed.not('hidden'), _dec6 = Ember.computed.and('_visible', 'fade'), _dec7 = (0, _typeClass.default)('alert', 'type'), _dec8 = (0, _usesTransition.default)('fade'), _dec9 = (0, _object.observes)('_visible'), _dec(_class = _dec2(_class = (_class2 = class Alert extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * A dismissible alert will have a close button in the upper right corner, that the user can click to dismiss
       * the alert.
       *
       * @property dismissible
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "dismissible", _descriptor, this);
      /**
       * If true the alert is completely hidden. Will be set when the fade animation has finished.
       *
       * @property hidden
       * @type boolean
       * @default false
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "hidden", _descriptor2, this);
      /**
       * This property controls if the alert should be visible. If false it might still be in the DOM until the fade animation
       * has completed.
       *
       * When the alert is dismissed by user interaction this property will not update by using two-way bindings in order
       * to follow DDAU best practices. If you want to react to such changes, subscribe to the `onDismiss` action
       *
       * @property visible
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "visible", _descriptor3, this);
      /**
       * @property _visible
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "_visible", _descriptor4, this);
      /**
       * @property notVisible
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "notVisible", _descriptor5, this);
      /**
       * Set to false to disable the fade out animation when hiding the alert.
       *
       * @property fade
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "fade", _descriptor6, this);
      /**
       * Computed property to set the alert class to the component div. Will be false when dismissed to have the component
       * div (which cannot be removed form DOM by the component itself) without any markup.
       *
       * @property alert
       * @type boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "alert", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "showAlert", _descriptor8, this);
      /**
       * The duration of the fade out animation
       *
       * @property fadeDuration
       * @type number
       * @default 150
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "fadeDuration", _descriptor9, this);
      /**
       * Property for type styling
       *
       * For the available types see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/alerts/)
       *
       * @property type
       * @type String
       * @default 'default'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "typeClass", _descriptor11, this);
      /**
       * Use CSS transitions?
       *
       * @property usesTransition
       * @type boolean
       * @readonly
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "usesTransition", _descriptor12, this);
    }
    /**
     * The action to be sent after the alert has been dismissed (including the CSS transition).
     *
     * @event onDismissed
     * @public
     */
    onDismissed() {}

    /**
     * The action is called when the close button is clicked.
     *
     * You can return false to prevent closing the alert automatically, and do that in your action by
     * setting `visible` to false.
     *
     * @event onDismiss
     * @public
     */
    onDismiss() {}
    dismiss() {
      if (this.get('onDismiss')() !== false) {
        this.set('_visible', false);
      }
    }

    /**
     * Call to make the alert visible again after it has been hidden
     *
     * @method show
     * @private
     */
    show() {
      this.set('hidden', false);
    }

    /**
     * Call to hide the alert. If the `fade` property is true, this will fade out the alert before being finally
     * dismissed.
     *
     * @method hide
     * @private
     */
    hide() {
      if (this.get('usesTransition')) {
        Ember.run.later(this, function () {
          if (!this.get('isDestroyed')) {
            this.set('hidden', true);
            this.get('onDismissed')();
          }
        }, this.get('fadeDuration'));
      } else {
        this.set('hidden', true);
        this.get('onDismissed')();
      }
    }
    _observeIsVisible() {
      if (this.get('_visible')) {
        this.show();
      } else {
        this.hide();
      }
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dismissible", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hidden", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return !this.get('_visible');
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "visible", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "_visible", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "notVisible", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "alert", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showAlert", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fadeDuration", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 150;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "typeClass", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "usesTransition", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dismiss", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "dismiss"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "_observeIsVisible", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "_observeIsVisible"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = Alert;
});