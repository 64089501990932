define("ember-google-maps/mixins/register-events", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Add event listeners on a target object using the cross-browser event
   * listener library provided by the Google Maps API.
   *
   * @param {Object} target
   * @param {Events} events
   * @param {[Object]} payload = {} An optional object of additional parameters
   *     to include with the event payload.
   * @return {google.maps.MapsEventListener[]} An array of bound event listeners
   *     that should be used to remove the listeners when no longer needed.
   */
  function _addEventListeners(target, events) {
    let payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return Object.entries(events).map(_ref => {
      let [originalEventName, action] = _ref;
      return _addEventListener(target, originalEventName, action, payload);
    });
  }
  function _addEventListener(target, originalEventName, action) {
    let payload = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    let eventName = Ember.String.decamelize(originalEventName).slice(3);
    function callback(googleEvent) {
      let params = (0, _objectSpread2.default)({
        event: window.event,
        googleEvent,
        eventName,
        target
      }, payload);
      Ember.run.join(target, action, params);
    }
    let listener = google.maps.event.addDomListener(target, eventName, callback);
    return {
      name: eventName,
      listener,
      remove: () => listener.remove()
    };
  }

  /**
   * Register Google Maps events on any map component.
   *
   * The mixin filters the component attributes for those that begin with `on` and
   * are not on the `_ignoredAttrs` list. The attribute name is decamelize and the
   * `on` prefixed is dropped to generate the event name. The attribute function
   * is then bound to that event by name.
   *
   * For example, passing `onClick` will add a `click` event that will
   * call the function passed in as `onClick`.
   *
   * @class RegisterEvents
   * @module ember-google-maps/mixins/register-events
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    /**
     * @property events
     * @type {Object}
     * @public
     */

    /**
     * The target DOM node or Google Maps object to which to attach event
     * listeners.
     *
     * @property eventTarget
     * @type {HTMLNode|MVCObject}
     * @private
     */
    _eventTarget: Ember.computed.reads('mapComponent'),
    /**
     * Filter the array of passed attributes for attributes that begin with `on`.
     *
     * @property _eventAttrs
     * @private
     * @return {Array} An array of extracted event names.
     */
    _eventAttrs: Ember.computed('attrs', function () {
      let attrNames = Object.keys(this.attrs);
      return attrNames.filter(attr => this._filterEventsByName(attr));
    }),
    /**
     * A combination of events passed via the `events` hash and extracted from the
     * component's attributes. Events registered via an attribute take precedence.
     *
     * @property _events
     * @private
     * @return {Object}
     */
    _events: Ember.computed('events', '_eventAttrs', function () {
      let events = Ember.get(this, 'events');
      let extractedEvents = Ember.getProperties(this, Ember.get(this, '_eventAttrs'));
      return Ember.assign({}, events, extractedEvents);
    }),
    /**
     * Return true if the passed attribute matches the syntax for an event, i.e.
     * begins with `on` and is not explicitly ignored in `_ignoredAttrs`.
     *
     * @method _filterEventsByName
     * @param {String} attr
     * @private
     * @return {Boolean}
     */
    _filterEventsByName(attr) {
      return attr.slice(0, 2) === 'on' && Ember.get(this, '_ignoredAttrs').indexOf(attr) === -1;
    },
    init() {
      this._super(...arguments);
      this._eventListeners = new Map();
    },
    willDestroyElement() {
      this._eventListeners.forEach(remove => remove());
      this._super(...arguments);
    },
    /**
     * Register an event listener on the eventTarget for each event provided.
     *
     * @method registerEvents
     * @private
     * @return
     */
    registerEvents() {
      let eventTarget = Ember.get(this, '_eventTarget');
      let events = Ember.get(this, '_events');
      let payload = {
        publicAPI: this.publicAPI,
        map: Ember.get(this, 'map')
      };
      _addEventListeners(eventTarget, events, payload).forEach(_ref2 => {
        let {
          name,
          remove
        } = _ref2;
        return this._eventListeners.set(name, remove);
      });
    }
  });
  _exports.default = _default;
});