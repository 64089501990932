define("ember-bootstrap/components/base/bs-modal/dialog", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-modal/dialog", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _dialog, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  /**
   Internal component for modal's markup and event handling. Should not be used directly.
  
   @class ModalDialog
   @namespace Components
   @extends Ember.Component
   @private
   */
  let ModalDialog = (_dec = (0, _component.layout)(_dialog.default), _dec2 = (0, _component.classNames)('modal'), _dec3 = (0, _component.classNameBindings)('fade'), _dec4 = (0, _component.attributeBindings)('tabindex', 'ariaLabelledby:aria-labelledby'), _dec5 = Ember.computed.readOnly('titleId'), _dec6 = Ember.computed('size').readOnly(), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class ModalDialog extends Ember.Component {
    constructor() {
      super(...arguments);
      this.ariaRole = 'dialog';
      this.tabindex = '-1';
      (0, _initializerDefineProperty2.default)(this, "ariaLabelledby", _descriptor, this);
      /**
       * Set to false to disable fade animations.
       *
       * @property fade
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "fade", _descriptor2, this);
      /**
       * Used to apply Bootstrap's visibility classes
       *
       * @property showModal
       * @type boolean
       * @default false
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "showModal", _descriptor3, this);
      /**
       * Render modal markup?
       *
       * @property inDom
       * @type boolean
       * @default false
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "inDom", _descriptor4, this);
      /**
       * @property paddingLeft
       * @type number|null
       * @default null
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "paddingLeft", _descriptor5, this);
      /**
       * @property paddingRight
       * @type number|null
       * @default null
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "paddingRight", _descriptor6, this);
      /**
       * Closes the modal when escape key is pressed.
       *
       * @property keyboard
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "keyboard", _descriptor7, this);
      /**
       * Property for size styling, set to null (default), 'lg' or 'sm'
       *
       * Also see the [Bootstrap docs](http://getbootstrap.com/javascript/#modals-sizes)
       *
       * @property size
       * @type String
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "size", _descriptor8, this);
      /**
       * If true clicking on the backdrop will close the modal.
       *
       * @property backdropClose
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "backdropClose", _descriptor9, this);
      /**
       * The id of the `.modal-title` element
       *
       * @property titleId
       * @type string
       * @default null
       * @private
       */
      this.titleId = null;
    }
    /**
     * Name of the size class
     *
     * @property sizeClass
     * @type string
     * @readOnly
     * @private
     */
    get sizeClass() {
      let size = this.get('size');
      return Ember.isBlank(size) ? null : `modal-${size}`;
    }
    /**
     * Gets or sets the id of the title element for aria accessibility tags
     *
     * @method getSetTitleID
     * @private
     */
    getOrSetTitleId() {
      //Title element may be set by user so we have to try and find it to set the id
      const modalNode = this.get('element');
      let nodeId = null;
      if (modalNode) {
        const titleNode = modalNode.querySelector('.modal-title');
        if (titleNode) {
          //Get title id of .modal-title
          nodeId = titleNode.id;
          if (!nodeId) {
            //no title id so we set one
            nodeId = `${this.get('id')}-title`;
            titleNode.id = nodeId;
          }
        }
      }
      this.set('titleId', nodeId);
    }

    /**
     * Update the elements styles using CSSOM.
     *
     * This is necessary cause binding style attribute would require a
     * Content-Security-Policy `style-src 'unsafe-line'`.
     *
     * @method updateStyle
     * @return void
     * @private
     */
    updateStyles() {
      let {
        inDom,
        paddingLeft,
        paddingRight
      } = this.getProperties('inDom', 'paddingLeft', 'paddingRight');
      this.element.style.display = inDom ? 'block' : '';
      this.element.style.paddingLeft = paddingLeft || '';
      this.element.style.paddingRight = paddingRight || '';
    }

    /**
     * @event onClose
     * @public
     */
    onClose() {}
    keyDown(e) {
      let code = e.keyCode || e.which;
      if (code === 27 && this.get('keyboard')) {
        this.get('onClose')();
      }
    }
    _click(e) {
      if (e.target !== this.element || !this.get('backdropClose')) {
        return;
      }
      this.get('onClose')();
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      // Ember events use event delegation, but we need to add an `onclick` handler directly on the modal element for
      // iOS to allow clicking the div. So a `click(){}` method here won't work, we need to attach an event listener
      // directly to the element
      this.element.onclick = Ember.run.bind(this, this._click);
      this.getOrSetTitleId();
      this.updateStyles();
    }
    didUpdateAttrs() {
      this.updateStyles();
    }
    willDestroyElement() {
      this.element.onclick = null;
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "ariaLabelledby", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showModal", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "inDom", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "paddingLeft", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "paddingRight", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "keyboard", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "backdropClose", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sizeClass", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "sizeClass"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = ModalDialog;
});