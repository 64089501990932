define("ember-bootstrap/templates/components/bs-tooltip/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HwHFgbSd",
    "block": "{\"symbols\":[\"@destinationElement\",\"@id\",\"&attrs\",\"&default\"],\"statements\":[[5,\"ember-popper\",[[13,3]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@id\",\"@class\"],[[23,0,[\"ariaRole\"]],[23,0,[\"placement\"]],[23,0,[\"renderInPlace\"]],[23,0,[\"popperTarget\"]],[23,0,[\"popperModifiers\"]],[23,1,[]],[23,0,[\"updatePlacement\"]],[23,0,[\"updatePlacement\"]],[23,2,[]],[23,0,[\"popperClass\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[23,0,[\"arrowClass\"]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"tooltip-inner\"],[8],[0,\"\\n    \"],[14,4],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip/element.hbs"
    }
  });
  _exports.default = _default;
});