define("ember-bootstrap/components/base/bs-accordion/item/body", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-accordion/body", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _body, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  /**
   Component for an accordion item body.
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionItemBody
   @namespace Components
   @extends Ember.Component
   @public
   */
  let AccordionItemBody = (_dec = (0, _component.layout)(_body.default), _dec2 = (0, _component.tagName)(''), _dec(_class = _dec2(_class = (_class2 = class AccordionItemBody extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * @property collapsed
       * @type boolean
       * @public
       */
      /**
       * @property collapseComponent
       * @type {String}
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "collapseComponent", _descriptor, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "collapseComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-collapse';
    }
  })), _class2)) || _class) || _class);
  _exports.default = AccordionItemBody;
});