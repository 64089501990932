define("ember-bootstrap/components/base/bs-dropdown/menu", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-dropdown/menu", "ember-bootstrap/utils/dom", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _menu, _dom, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  /**
   Component for the dropdown menu.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownMenu
   @namespace Components
   @extends Ember.Component
   @public
   */
  let DropdownMenu = (_dec = (0, _component.layout)(_menu.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.computed('renderInPlace'), _dec4 = Ember.computed('align'), _dec5 = Ember.computed('direction', 'align'), _dec6 = Ember.computed('inNav', 'flip'), _dec(_class = _dec2(_class = (_class2 = class DropdownMenu extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * @property ariaRole
       * @default menu
       * @type string
       * @protected
       */
      this.ariaRole = 'menu';
      /**
       * Alignment of the menu, either "left" or "right"
       *
       * @property align
       * @type string
       * @default left
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "align", _descriptor, this);
      /**
       * @property direction
       * @default 'down'
       * @type string
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "direction", _descriptor2, this);
      /**
       * @property inNav
       * @type {boolean}
       * @private
       */
      this.inNav = false;
      /**
       * By default the menu is rendered in the same place the dropdown. If you experience clipping
       * issues, you can set this to false to render the menu in a wormhole at the top of the DOM.
       *
       * @property renderInPlace
       * @type boolean
       * @default true
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "renderInPlace", _descriptor3, this);
      this._isOpen = false;
      this.flip = true;
      this._popperApi = null;
      /**
       * @property itemComponent
       * @type {String}
       * @private
       */
      this.itemComponent = 'bs-dropdown/menu/item';
      /**
       * @property linkToComponent
       * @type {String}
       * @private
       */
      this.linkToComponent = 'bs-dropdown/menu/link-to';
      /**
       * @property dividerComponent
       * @type {String}
       * @private
       */
      this.dividerComponent = 'bs-dropdown/menu/divider';
    }
    /**
     * @property _renderInPlace
     * @type boolean
     * @private
     */
    get _renderInPlace() {
      return this.get('renderInPlace') || !this.destinationElement;
    }

    /**
     * The wormhole destinationElement
     *
     * @property destinationElement
     * @type object
     * @readonly
     * @private
     */
    get destinationElement() {
      return (0, _dom.getDestinationElement)(this);
    }
    get alignClass() {
      return this.get('align') !== 'left' ? `dropdown-menu-${this.get('align')}` : undefined;
    }
    get isOpen() {
      return false;
    }
    set isOpen(value) {
      // delay removing the menu from DOM to allow (delegated Ember) event to fire for the menu's children
      // Fixes https://github.com/kaliber5/ember-bootstrap/issues/660
      Ember.run.next(() => {
        if (this.get('isDestroying') || this.get('isDestroyed')) {
          return;
        }
        this.set('_isOpen', value);
      });
      return value;
    }
    get popperPlacement() {
      let placement = 'bottom-start';
      let {
        direction,
        align
      } = this.getProperties('direction', 'align');
      if (direction === 'up') {
        placement = 'top-start';
        if (align === 'right') {
          placement = 'top-end';
        }
      } else if (direction === 'left') {
        placement = 'left-start';
      } else if (direction === 'right') {
        placement = 'right-start';
      } else if (align === 'right') {
        placement = 'bottom-end';
      }
      return placement;
    }
    get popperModifiers() {
      return {
        // @todo add offset config
        applyStyle: {
          enabled: !this.get('inNav')
        },
        flip: {
          enabled: this.get('flip')
        }
      };
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "align", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'left';
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "direction", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'down';
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "renderInPlace", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "_renderInPlace", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "_renderInPlace"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "destinationElement", [Ember.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "destinationElement"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "alignClass", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "alignClass"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isOpen", [Ember.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "isOpen"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "popperPlacement", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "popperPlacement"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "popperModifiers", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "popperModifiers"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = DropdownMenu;
});