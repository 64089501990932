define("ember-google-maps/components/g-map/map-component", ["exports", "ember-google-maps/mixins/process-options", "ember-google-maps/mixins/register-events", "ember-google-maps/utils/public-api"], function (_exports, _processOptions, _registerEvents, _publicApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MapComponentAPI = {
    map: 'map',
    mapComponent: 'mapComponent',
    isInitialized: 'isInitialized',
    actions: {
      update: '_updateComponent'
    }
  };

  /**
   * @class MapComponent
   * @module ember-google-maps/components/g-map/map-component
   * @namespace GMap
   * @extends Component
   * @uses ProcessOptions
   * @uses RegisterEvents
   */
  const MapComponent = Ember.Component.extend(_processOptions.default, _registerEvents.default, {
    tagName: '',
    _type: null,
    _requiredOptions: ['map'],
    init() {
      this._super(...arguments);
      (true && !(this._type) && Ember.assert('You must set a _type property on the map component.', this._type));
      this._registrationType = this._pluralType || `${this._type}s`;
      this.isInitialized = Ember.RSVP.defer();
      this.isInitialized.promise.then(() => Ember.set(this, '_isInitialized', true));
      this.publicAPI = new _publicApi.default(this, MapComponentAPI);
    },
    didInsertElement() {
      this._super(...arguments);
      this._internalAPI._registerComponent(this._registrationType, this.publicAPI);
      this._updateOrAddComponent();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this._updateOrAddComponent();
    },
    willDestroyElement() {
      this._super(...arguments);
      Ember.tryInvoke(this.mapComponent, 'setMap', [null]);
      this.publicAPI.remove(this);
      this._internalAPI._unregisterComponent(this._registrationType, this.publicAPI);
    },
    _updateOrAddComponent() {
      if (!Ember.get(this, 'map')) {
        return;
      }
      if (this._isInitialized) {
        this._updateComponent();
      } else {
        Ember.RSVP.resolve().then(() => this._addComponent()).then(() => this._didAddComponent());
      }
    },
    /**
     * Run when the map component is first initialized. Normally this happens as
     * soon as the map is ready.
     *
     * @method _addComponent
     * @return
     */
    _addComponent() {
      (true && !(false) && Ember.assert('Map components must implement the _addComponent hook.'));
    },
    /**
     * Run after the map component has been initialized. This hook should be used
     * to register events, etc.
     *
     * @method _didAddComponent
     * @return
     */
    _didAddComponent() {
      this._registerOptionObservers();
      this.registerEvents();
      this.isInitialized.resolve();
    },
    /**
     * Run when any of the attributes or watched options change.
     *
     * @method _updateComponent
     * @return
     */
    _updateComponent() {
      let options = Ember.get(this, '_options');
      this.mapComponent.setOptions(options);
    }
  });
  var _default = MapComponent;
  _exports.default = _default;
});