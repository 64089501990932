define("ember-bootstrap/components/base/bs-form/element/label", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-form/element/label", "ember-bootstrap/utils/default-decorator"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _label, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  /**
  
   @class FormElementLabel
   @namespace Components
   @extends Ember.Component
   @private
   */
  let FormElementLabel = (_dec = (0, _component.layout)(_label.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.computed.equal('controlType', 'checkbox').readOnly(), _dec4 = Ember.computed.equal('formLayout', 'horizontal').readOnly(), _dec(_class = _dec2(_class = (_class2 = class FormElementLabel extends Ember.Component {
    constructor() {
      super(...arguments);
      /**
       * @property label
       * @type string
       * @public
       */
      /**
       * @property invisibleLabel
       * @type boolean
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "invisibleLabel", _descriptor, this);
      /**
       * @property formElementId
       * @type {String}
       * @public
       */
      /**
       * @property labelClass
       * @type {String}
       * @public
       */
      /**
       * The form layout used for the markup generation (see http://getbootstrap.com/css/#forms):
       *
       * * 'horizontal'
       * * 'vertical'
       * * 'inline'
       *
       * Defaults to the parent `form`'s `formLayout` property.
       *
       * @property formLayout
       * @type string
       * @default 'vertical'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "formLayout", _descriptor2, this);
      /**
       * The type of the control widget.
       * Supported types:
       *
       * * 'text'
       * * 'checkbox'
       * * 'textarea'
       * * any other type will use an input tag with the `controlType` value as the type attribute (for e.g. HTML5 input
       * types like 'email'), and the same layout as the 'text' type
       *
       * @property controlType
       * @type string
       * @default 'text'
       * @public
       */
      (0, _initializerDefineProperty2.default)(this, "controlType", _descriptor3, this);
      /**
       * Indicates whether the type of the control widget equals `checkbox`
       *
       * @property isCheckbox
       * @type boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "isCheckbox", _descriptor4, this);
      /**
       * Indicates whether the form type equals `horizontal`
       *
       * @property isHorizontal
       * @type boolean
       * @private
       */
      (0, _initializerDefineProperty2.default)(this, "isHorizontal", _descriptor5, this);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "invisibleLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "formLayout", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'vertical';
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "controlType", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'text';
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isCheckbox", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isHorizontal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = FormElementLabel;
});